// *****************************************
// AUTOGENERATED DO NOT EDIT
// To refresh this file run: `npm run dev:graphql`
// (@see codegen.yml)
// *****************************************
/* eslint-disable */

import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    JSON: any;
    Sku: any;
};

export type BffCartAddress = {
    __typename?: 'BffCartAddress';
    additionalAddressInfo?: Maybe<Scalars['String']>;
    apartment?: Maybe<Scalars['String']>;
    billing?: Maybe<Scalars['Boolean']>;
    buildingName?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    defaultShippingAddress?: Maybe<Scalars['Boolean']>;
    email?: Maybe<Scalars['String']>;
    firstName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    locality?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    region?: Maybe<Scalars['String']>;
    salutation?: Maybe<Scalars['String']>;
    shipping?: Maybe<Scalars['Boolean']>;
    streetName?: Maybe<Scalars['String']>;
    streetNumber?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type BffCartAddressUpdate = {
    additionalAddressInfo?: InputMaybe<Scalars['String']>;
    additionalStreetInfo?: InputMaybe<Scalars['String']>;
    apartment?: InputMaybe<Scalars['String']>;
    billing?: InputMaybe<Scalars['Boolean']>;
    buildingName?: InputMaybe<Scalars['String']>;
    city: Scalars['String'];
    country: Scalars['String'];
    defaultShippingAddress?: InputMaybe<Scalars['Boolean']>;
    department?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    fax?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    id?: InputMaybe<Scalars['String']>;
    lastName: Scalars['String'];
    locality?: InputMaybe<Scalars['String']>;
    mobile?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    poBox?: InputMaybe<Scalars['String']>;
    postalCode: Scalars['String'];
    region?: InputMaybe<Scalars['String']>;
    salutation: Scalars['String'];
    shipping?: InputMaybe<Scalars['Boolean']>;
    state?: InputMaybe<Scalars['String']>;
    streetName: Scalars['String'];
    streetNumber: Scalars['String'];
    taxNumber?: InputMaybe<Scalars['String']>;
    title?: InputMaybe<Scalars['String']>;
};

export type BffCartPaymentValue = {
    __typename?: 'BffCartPaymentValue';
    currency: Scalars['String'];
    value: Scalars['Int'];
};

export type BffCartResult = {
    __typename?: 'BffCartResult';
    anayltics?: Maybe<Scalars['JSON']>;
    billingAddress?: Maybe<BffCartAddress>;
    canSplitOrder: Scalars['Boolean'];
    cartItems: Array<BffCartResultCartItem>;
    count: Scalars['Int'];
    custom?: Maybe<Scalars['JSON']>;
    discountCode?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    isBlocked: Scalars['Boolean'];
    isOrderSplit: Scalars['Boolean'];
    messages: Array<BffCartResultMessage>;
    payment?: Maybe<Scalars['JSON']>;
    paymentAmount?: Maybe<BffCartPaymentValue>;
    shippingAddress?: Maybe<BffCartAddress>;
    showReturnNote: Scalars['Boolean'];
    skipPayment: Scalars['Boolean'];
    totals: Array<BffCartResultTotalPrice>;
    version?: Maybe<Scalars['String']>;
};

export type BffCartResultPaymentArgs = {
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
};

export type BffCartResultCartItem = {
    __typename?: 'BffCartResultCartItem';
    delivery?: Maybe<BffCartResultCartItemDelivery>;
    id: Scalars['String'];
    lineItems?: Maybe<Array<BffCartResultItem>>;
    shippingCost?: Maybe<BffCartResultShippingCost>;
    shippingOption?: Maybe<BffCartResultShippingOption>;
};

export type BffCartResultCartItemDelivery = {
    __typename?: 'BffCartResultCartItemDelivery';
    calendarEndDate: Scalars['String'];
    calendarStartDate: Scalars['String'];
    deliveryEndDate: Scalars['String'];
    deliveryStartDate: Scalars['String'];
    desiredDeliveryDate: Scalars['String'];
    weekRange: Scalars['String'];
};

export type BffCartResultDelivery = {
    __typename?: 'BffCartResultDelivery';
    calendarEndDate?: Maybe<Scalars['String']>;
    calendarStartDate?: Maybe<Scalars['String']>;
    deliveryEndDate?: Maybe<Scalars['String']>;
    deliveryStartDate?: Maybe<Scalars['String']>;
    desiredDeliveryDate?: Maybe<Scalars['String']>;
    weekRange?: Maybe<Scalars['String']>;
};

export type BffCartResultItem = {
    __typename?: 'BffCartResultItem';
    articleNo: Scalars['String'];
    delivery?: Maybe<BffCartResultDelivery>;
    id: Scalars['String'];
    image: BffCartResultItemImage;
    isConfigurable: Scalars['Boolean'];
    messages: Array<BffCartResultMessage>;
    name: Scalars['String'];
    orderLimit: Scalars['Int'];
    quantity: Scalars['Int'];
    showQuantity: Scalars['Boolean'];
    showReturnNote: Scalars['Boolean'];
    sku: Scalars['String'];
    totalPrice: BffProductPrice;
    type: Scalars['String'];
};

export type BffCartResultItemImage = {
    __typename?: 'BffCartResultItemImage';
    height: Scalars['Int'];
    src: Scalars['String'];
    width: Scalars['Int'];
};

export type BffCartResultMessage = {
    __typename?: 'BffCartResultMessage';
    message: Scalars['String'];
    sku?: Maybe<Scalars['String']>;
    type: Scalars['String'];
};

export type BffCartResultShippingCost = {
    __typename?: 'BffCartResultShippingCost';
    id?: Maybe<Scalars['String']>;
    price: BffCartResultShippingCostPrice;
    shippingType: Scalars['String'];
};

export type BffCartResultShippingCostPrice = {
    __typename?: 'BffCartResultShippingCostPrice';
    currencyCode?: Maybe<Scalars['String']>;
    currencySymbol?: Maybe<Scalars['String']>;
    formatted: Scalars['String'];
    raw?: Maybe<Scalars['Float']>;
};

export type BffCartResultShippingOption = {
    __typename?: 'BffCartResultShippingOption';
    id?: Maybe<Scalars['String']>;
    items: Array<BffCartResultShippingOptionItem>;
};

export type BffCartResultShippingOptionItem = {
    __typename?: 'BffCartResultShippingOptionItem';
    description: Scalars['String'];
    key: Scalars['String'];
    price: BffCartResultShippingCostPrice;
    selected: Scalars['Boolean'];
};

export type BffCartResultTotalPrice = {
    __typename?: 'BffCartResultTotalPrice';
    currencyCode?: Maybe<Scalars['String']>;
    formatted: Scalars['String'];
    key: Scalars['String'];
    rate?: Maybe<Scalars['String']>;
    raw?: Maybe<Scalars['Float']>;
};

export type BffCartShareResult = {
    __typename?: 'BffCartShareResult';
    url?: Maybe<Scalars['String']>;
};

export enum BffCountry {
    At = 'AT',
    Be = 'BE',
    Ch = 'CH',
    Cz = 'CZ',
    De = 'DE',
    Dk = 'DK',
    Es = 'ES',
    Fi = 'FI',
    Fr = 'FR',
    Gb = 'GB',
    Ie = 'IE',
    It = 'IT',
    Li = 'LI',
    Lu = 'LU',
    Nl = 'NL',
    No = 'NO',
    Pl = 'PL',
    Pt = 'PT',
    Se = 'SE',
    Us = 'US'
}

export type BffCustomerAddress = {
    __typename?: 'BffCustomerAddress';
    additionalAddressInfo?: Maybe<Scalars['String']>;
    apartment?: Maybe<Scalars['String']>;
    billing?: Maybe<Scalars['Boolean']>;
    buildingName?: Maybe<Scalars['String']>;
    city: Scalars['String'];
    country: Scalars['String'];
    defaultShippingAddress: Scalars['Boolean'];
    firstName: Scalars['String'];
    id: Scalars['String'];
    lastName: Scalars['String'];
    locality?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    postalCode: Scalars['String'];
    region?: Maybe<Scalars['String']>;
    salutation: Scalars['String'];
    shipping?: Maybe<Scalars['Boolean']>;
    streetName: Scalars['String'];
    streetNumber: Scalars['String'];
    title?: Maybe<Scalars['String']>;
};

export type BffCustomerAddressDeleteResult = {
    __typename?: 'BffCustomerAddressDeleteResult';
    id: Scalars['String'];
};

export type BffCustomerAddressUpdate = {
    additionalAddressInfo?: InputMaybe<Scalars['String']>;
    addressType?: InputMaybe<McAddressRequestAddressTypeEnum>;
    apartment?: InputMaybe<Scalars['String']>;
    billing?: InputMaybe<Scalars['Boolean']>;
    buildingName?: InputMaybe<Scalars['String']>;
    city: Scalars['String'];
    country: Scalars['String'];
    defaultShippingAddress?: InputMaybe<Scalars['Boolean']>;
    email?: InputMaybe<Scalars['String']>;
    firstName?: InputMaybe<Scalars['String']>;
    id?: InputMaybe<Scalars['String']>;
    lastName?: InputMaybe<Scalars['String']>;
    locality?: InputMaybe<Scalars['String']>;
    mobile?: InputMaybe<Scalars['String']>;
    phone?: InputMaybe<Scalars['String']>;
    postalCode: Scalars['String'];
    region?: InputMaybe<Scalars['String']>;
    salutation: Scalars['String'];
    shipping?: InputMaybe<Scalars['Boolean']>;
    streetName: Scalars['String'];
    streetNumber: Scalars['String'];
    title?: InputMaybe<Scalars['String']>;
};

export type BffCustomerAddressUpdateResult = {
    __typename?: 'BffCustomerAddressUpdateResult';
    additionalAddressInfo?: Maybe<Scalars['String']>;
    apartment?: Maybe<Scalars['String']>;
    billing?: Maybe<Scalars['Boolean']>;
    buildingName?: Maybe<Scalars['String']>;
    city?: Maybe<Scalars['String']>;
    country?: Maybe<Scalars['String']>;
    defaultShippingAddress?: Maybe<Scalars['Boolean']>;
    firstName?: Maybe<Scalars['String']>;
    id?: Maybe<Scalars['String']>;
    lastName?: Maybe<Scalars['String']>;
    locality?: Maybe<Scalars['String']>;
    mobile?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    postalCode?: Maybe<Scalars['String']>;
    region?: Maybe<Scalars['String']>;
    salutation?: Maybe<Scalars['String']>;
    shipping?: Maybe<Scalars['Boolean']>;
    streetName?: Maybe<Scalars['String']>;
    streetNumber?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export type BffCustomerAddressesGetResult = {
    __typename?: 'BffCustomerAddressesGetResult';
    addresses?: Maybe<Array<BffCustomerAddress>>;
};

export type BffCustomerCreate = {
    companyName?: InputMaybe<Scalars['String']>;
    email: Scalars['String'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    password: Scalars['String'];
    preferredCountry: McCustomerCreateRequestPreferredCountryEnum;
    preferredLanguage: McCustomerCreateRequestPreferredLanguageEnum;
    salutation: Scalars['String'];
    title?: InputMaybe<Scalars['String']>;
    vatId?: InputMaybe<Scalars['String']>;
    vatIdCountry?: InputMaybe<Scalars['String']>;
};

export type BffCustomerModel = {
    __typename?: 'BffCustomerModel';
    addressBook: Array<BffCustomerAddress>;
    companyName?: Maybe<Scalars['String']>;
    customerNumber: Scalars['String'];
    customerType: Scalars['String'];
    email: Scalars['String'];
    firstName: Scalars['String'];
    id: Scalars['String'];
    lastName: Scalars['String'];
    orders?: Maybe<Array<BffOrderSummary>>;
    preferredCountry?: Maybe<Scalars['String']>;
    preferredLanguage?: Maybe<Scalars['String']>;
    salutation: Scalars['String'];
    title?: Maybe<Scalars['String']>;
    vatId?: Maybe<Scalars['String']>;
    vatIdCountry?: Maybe<Scalars['String']>;
};

export type BffCustomerModelAddressBookArgs = {
    country: BffCountry;
};

export type BffCustomerModelOrdersArgs = {
    country: BffCountry;
};

export type BffCustomerShoppingListsCreateResult = {
    lineItems: BffShoppingListLineItemInput;
};

export type BffCustomerUpdate = {
    companyName?: InputMaybe<Scalars['String']>;
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    salutation: Scalars['String'];
};

export type BffCustomerVerifyModel = {
    __typename?: 'BffCustomerVerifyModel';
    verified: Scalars['String'];
};

export enum BffDataSource {
    Censhare = 'censhare',
    Commercetools = 'commercetools'
}

export type BffDelivery = {
    __typename?: 'BffDelivery';
    cached?: Maybe<Scalars['Boolean']>;
    deliveryTime: Scalars['String'];
    endDate?: Maybe<Scalars['String']>;
    startDate?: Maybe<Scalars['String']>;
    type: BffDeliveryType;
    weekRange?: Maybe<Array<Scalars['Int']>>;
};

export type BffDeliveryModel = {
    __typename?: 'BffDeliveryModel';
    articleNo?: Maybe<Scalars['String']>;
    deliveryEndDate?: Maybe<Scalars['String']>;
    deliveryStartDate?: Maybe<Scalars['String']>;
    shippingCost?: Maybe<Scalars['Float']>;
    shippingTime?: Maybe<Scalars['Int']>;
    shippingTimeMax?: Maybe<Scalars['Int']>;
    shippingTimeMin?: Maybe<Scalars['Int']>;
    shippingType?: Maybe<Scalars['String']>;
    weekRange?: Maybe<Scalars['String']>;
};

export enum BffDeliveryType {
    DateRange = 'dateRange',
    FastShipping = 'fastShipping',
    NotAvailable = 'notAvailable',
    WeekRange = 'weekRange',
    WeekRangeFallback = 'weekRangeFallback'
}

export type BffError = {
    cart?: Maybe<BffCartResult>;
    message: Scalars['String'];
    name: Scalars['String'];
};

export enum BffLanguage {
    De = 'de',
    En = 'en',
    Es = 'es',
    Fr = 'fr',
    It = 'it',
    Ja = 'ja',
    Nl = 'nl'
}

export type BffLink = {
    __typename?: 'BffLink';
    href: Scalars['String'];
    id?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    title?: Maybe<Scalars['String']>;
};

export enum BffMarket {
    As = 'AS',
    At = 'AT',
    Au = 'AU',
    Be = 'BE',
    Br = 'BR',
    Ch = 'CH',
    Cn = 'CN',
    Cz = 'CZ',
    De = 'DE',
    Dk = 'DK',
    Es = 'ES',
    Fi = 'FI',
    Fr = 'FR',
    Gb = 'GB',
    Gr = 'GR',
    Hu = 'HU',
    Ie = 'IE',
    Is = 'IS',
    It = 'IT',
    Jp = 'JP',
    Li = 'LI',
    Lp = 'LP',
    Lu = 'LU',
    Me = 'ME',
    Mt = 'MT',
    Mx = 'MX',
    Nl = 'NL',
    No = 'NO',
    Pl = 'PL',
    Pt = 'PT',
    Se = 'SE',
    Tr = 'TR',
    Un = 'UN',
    Us = 'US',
    Vi = 'VI'
}

export type BffOrder = {
    __typename?: 'BffOrder';
    analytics?: Maybe<Scalars['JSON']>;
    billingAddress: BffCartAddress;
    date: Scalars['String'];
    deliveries: Array<BffOrderDelivery>;
    deliveryNote?: Maybe<Scalars['String']>;
    email: Scalars['String'];
    id: Scalars['String'];
    orderNumber: Scalars['String'];
    orderStatus: Scalars['String'];
    paymentBic?: Maybe<Scalars['String']>;
    paymentIban?: Maybe<Scalars['String']>;
    paymentMethod: Scalars['String'];
    paymentStatus: Scalars['String'];
    shippingAddress: BffCartAddress;
    totals: Array<BffCartResultTotalPrice>;
};

export type BffOrderDelivery = {
    __typename?: 'BffOrderDelivery';
    batchId: Scalars['String'];
    delivery: BffCartResultDelivery;
    id: Scalars['String'];
    items: Array<BffOrderDeliveryItems>;
    parcels: Array<BffOrderDeliveryParcel>;
    shippingDescription?: Maybe<Scalars['String']>;
    shippingOptionDescription?: Maybe<Scalars['String']>;
};

export type BffOrderDeliveryItems = {
    __typename?: 'BffOrderDeliveryItems';
    articleNumber?: Maybe<Scalars['String']>;
    id: Scalars['String'];
    image?: Maybe<BffCartResultItemImage>;
    itemStatus?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    quantity: Scalars['Int'];
    sku: Scalars['String'];
    totalPrice: BffProductPrice;
};

export type BffOrderDeliveryParcel = {
    __typename?: 'BffOrderDeliveryParcel';
    id?: Maybe<Scalars['String']>;
    trackingData?: Maybe<BffOrderDeliveryParcelTrackingData>;
};

export type BffOrderDeliveryParcelTrackingData = {
    __typename?: 'BffOrderDeliveryParcelTrackingData';
    carrier?: Maybe<Scalars['String']>;
    trackingId?: Maybe<Scalars['String']>;
};

export type BffOrderSummary = {
    __typename?: 'BffOrderSummary';
    date: Scalars['String'];
    id: Scalars['String'];
    numberOfArticles: Scalars['String'];
    orderNumber: Scalars['String'];
    orderState: Scalars['String'];
    paymentState: Scalars['String'];
    totalPrice: BffProductPrice;
};

export type BffProduct = {
    __typename?: 'BffProduct';
    analytics?: Maybe<Scalars['JSON']>;
    cached?: Maybe<Scalars['Boolean']>;
    configurations?: Maybe<Array<BffProductConfiguration>>;
    delivery?: Maybe<BffDelivery>;
    designer?: Maybe<Scalars['String']>;
    image?: Maybe<BffProductImage>;
    name: Scalars['String'];
    price?: Maybe<BffProductPrice>;
    requestedSku: Scalars['String'];
    showAddToCart: Scalars['Boolean'];
    sku: Scalars['String'];
};

export type BffProductConfiguration = {
    __typename?: 'BffProductConfiguration';
    id: Scalars['String'];
    image?: Maybe<BffProductImage>;
    selected: Scalars['String'];
    title: Scalars['String'];
    type: Scalars['String'];
    values?: Maybe<Array<BffProductConfigurationValue>>;
};

export type BffProductConfigurationValue = {
    __typename?: 'BffProductConfigurationValue';
    image?: Maybe<BffProductImage>;
    name: Scalars['String'];
    selected: Scalars['Boolean'];
    sku: Scalars['String'];
};

export type BffProductImage = {
    __typename?: 'BffProductImage';
    height?: Maybe<Scalars['Int']>;
    src: Scalars['String'];
    width?: Maybe<Scalars['Int']>;
};

export type BffProductInputProduct = {
    quantity?: InputMaybe<Scalars['Int']>;
    sku: Scalars['Sku'];
};

export type BffProductModel = {
    __typename?: 'BffProductModel';
    delivery?: Maybe<BffDeliveryModel>;
    designer?: Maybe<Scalars['String']>;
    image?: Maybe<BffCartResultItemImage>;
    name?: Maybe<Scalars['String']>;
    /** @deprecated Use ProductModelV2 instead */
    price?: Maybe<BffProductPriceModel>;
    quantity?: Maybe<Scalars['Int']>;
    sku: Scalars['String'];
    url?: Maybe<Scalars['String']>;
};

export type BffProductModelPriceArgs = {
    language: BffLanguage;
};

export type BffProductPrice = {
    __typename?: 'BffProductPrice';
    currencyCode?: Maybe<Scalars['String']>;
    /** @deprecated Use currencyCode */
    currencySymbol?: Maybe<Scalars['String']>;
    /** @deprecated Use raw */
    formatted?: Maybe<Scalars['String']>;
    raw?: Maybe<Scalars['Float']>;
};

export type BffProductPriceModel = {
    __typename?: 'BffProductPriceModel';
    articleNumber?: Maybe<Scalars['String']>;
    censhareId?: Maybe<Scalars['Int']>;
    censhareSlug?: Maybe<Scalars['String']>;
    configurationId?: Maybe<Scalars['String']>;
    grossPrice?: Maybe<BffProductPrice>;
    isFromPrice?: Maybe<Scalars['Boolean']>;
    netPrice?: Maybe<BffProductPrice>;
    price?: Maybe<BffProductPrice>;
};

export enum BffProfession {
    Architect = 'architect',
    Designer = 'designer',
    InteriorArchitect = 'interior_architect',
    Other = 'other',
    Private = 'private',
    Retailer = 'retailer'
}

export type BffProxyError = BffError & {
    __typename?: 'BffProxyError';
    cart?: Maybe<BffCartResult>;
    message: Scalars['String'];
    name: Scalars['String'];
    status: Scalars['Int'];
    traceId: Scalars['String'];
};

export type BffShoppingList = {
    __typename?: 'BffShoppingList';
    id: Scalars['String'];
    isCartShare: Scalars['Boolean'];
    lineItems: Array<BffShoppingListLineItem>;
};

export type BffShoppingListLineItem = {
    __typename?: 'BffShoppingListLineItem';
    censhareId: Scalars['String'];
    id: Scalars['String'];
    image: BffCartResultItemImage;
    isSellable: Scalars['Boolean'];
    link?: Maybe<BffLink>;
    name: Scalars['String'];
    productType: BffShoppingListLineItemProductTypeEnum;
    sku: Scalars['String'];
};

export type BffShoppingListLineItemInput = {
    sku: Scalars['String'];
};

export enum BffShoppingListLineItemProductTypeEnum {
    Material = 'material',
    Product = 'product'
}

export type BffShoppingListShareLink = {
    __typename?: 'BffShoppingListShareLink';
    encodedShoppingListId?: Maybe<Scalars['String']>;
    url?: Maybe<Scalars['String']>;
};

export enum BffStore {
    WebEu = 'web_EU',
    WebUs = 'web_US'
}

export type BffSubmitOrderResult = {
    __typename?: 'BffSubmitOrderResult';
    cartId: Scalars['String'];
    orderNumber?: Maybe<Scalars['String']>;
    payment: BffSubmitOrderResultPayment;
    success: Scalars['Boolean'];
};

export type BffSubmitOrderResultPayment = {
    __typename?: 'BffSubmitOrderResultPayment';
    action?: Maybe<Scalars['JSON']>;
    paymentMethod?: Maybe<Scalars['JSON']>;
    pspReference?: Maybe<Scalars['String']>;
    refusalReason?: Maybe<Scalars['String']>;
    refusalReasonCode?: Maybe<Scalars['String']>;
};

export type BffUpdateDesiredDeilveryDateResult = {
    __typename?: 'BffUpdateDesiredDeilveryDateResult';
    calendarEndDate?: Maybe<Scalars['String']>;
    calendarStartDate?: Maybe<Scalars['String']>;
    deliveryTime?: Maybe<Scalars['String']>;
    desiredDateSelected?: Maybe<Scalars['Boolean']>;
    shippingBatchId?: Maybe<Scalars['String']>;
    userSelectedDeliveryEndDate?: Maybe<Scalars['String']>;
    userSelectedDeliveryStartDate?: Maybe<Scalars['String']>;
};

export type BffVatResponse = {
    __typename?: 'BffVatResponse';
    isValid: Scalars['Boolean'];
    name?: Maybe<Scalars['String']>;
    vatNumber: Scalars['String'];
};

export type Error = {
    __typename?: 'Error';
    message: Scalars['String'];
};

export enum McAddressRequestAddressTypeEnum {
    Billing = 'BILLING',
    Shipping = 'SHIPPING'
}

export enum McCustomerCreateRequestPreferredCountryEnum {
    Ad = 'AD',
    Ae = 'AE',
    Ao = 'AO',
    Ar = 'AR',
    At = 'AT',
    Au = 'AU',
    Ba = 'BA',
    Be = 'BE',
    Bg = 'BG',
    Bh = 'BH',
    Bq = 'BQ',
    Br = 'BR',
    By = 'BY',
    Ca = 'CA',
    Ch = 'CH',
    Cl = 'CL',
    Cn = 'CN',
    Co = 'CO',
    Cw = 'CW',
    Cy = 'CY',
    Cz = 'CZ',
    De = 'DE',
    Dk = 'DK',
    Do = 'DO',
    Ec = 'EC',
    Ee = 'EE',
    Eg = 'EG',
    Es = 'ES',
    Fi = 'FI',
    Fo = 'FO',
    Fr = 'FR',
    Gb = 'GB',
    Ge = 'GE',
    Gr = 'GR',
    Gt = 'GT',
    Hk = 'HK',
    Hr = 'HR',
    Hu = 'HU',
    Id = 'ID',
    Ie = 'IE',
    Il = 'IL',
    In = 'IN',
    Ir = 'IR',
    Is = 'IS',
    It = 'IT',
    Jo = 'JO',
    Jp = 'JP',
    Kr = 'KR',
    Kw = 'KW',
    Kz = 'KZ',
    Lb = 'LB',
    Li = 'LI',
    Lk = 'LK',
    Lt = 'LT',
    Lu = 'LU',
    Lv = 'LV',
    Ma = 'MA',
    Mk = 'MK',
    Mt = 'MT',
    Mx = 'MX',
    My = 'MY',
    Nc = 'NC',
    Nl = 'NL',
    No = 'NO',
    Nz = 'NZ',
    Om = 'OM',
    Pa = 'PA',
    Pe = 'PE',
    Ph = 'PH',
    Pl = 'PL',
    Pr = 'PR',
    Ps = 'PS',
    Pt = 'PT',
    Qa = 'QA',
    Ro = 'RO',
    Rs = 'RS',
    Ru = 'RU',
    Sa = 'SA',
    Se = 'SE',
    Sg = 'SG',
    Si = 'SI',
    Sk = 'SK',
    Th = 'TH',
    Tn = 'TN',
    Tr = 'TR',
    Tw = 'TW',
    Ua = 'UA',
    Us = 'US',
    Uy = 'UY',
    Ve = 'VE',
    Ye = 'YE',
    Za = 'ZA'
}

export enum McCustomerCreateRequestPreferredLanguageEnum {
    De = 'de',
    En = 'en',
    Es = 'es',
    Fr = 'fr',
    It = 'it',
    Ja = 'ja',
    Nl = 'nl',
    Zh = 'zh'
}

export type Mutation = {
    __typename?: 'Mutation';
    addDiscountCodeToCart: MutationAddDiscountCodeToCartResult;
    addToCart: MutationAddToCartResult;
    changeCart: MutationChangeCartResult;
    changeCartAddresses: MutationChangeCartAddressesResult;
    createCartShare: MutationCreateCartShareResult;
    createCustomer: MutationCreateCustomerResult;
    createCustomerAddress: MutationCreateCustomerAddressResult;
    createCustomerShoppingList: MutationCreateCustomerShoppingListResult;
    deleteCustomerAddress: MutationDeleteCustomerAddressResult;
    deleteCustomerShoppingListItem: MutationDeleteCustomerShoppingListItemResult;
    initiatePayment?: Maybe<MutationInitiatePaymentResult>;
    removeDiscountCodeFromCart: MutationRemoveDiscountCodeFromCartResult;
    splitOrder: MutationSplitOrderResult;
    submitExpressOrder?: Maybe<MutationSubmitExpressOrderResult>;
    submitMaterialsOrder?: Maybe<MutationSubmitMaterialsOrderResult>;
    submitOrder?: Maybe<MutationSubmitOrderResult>;
    submitPaymentAction?: Maybe<MutationSubmitPaymentActionResult>;
    updateCustomer: MutationUpdateCustomerResult;
    updateCustomerAddress: MutationUpdateCustomerAddressResult;
    updateDeliveryNoteAndProfession?: Maybe<MutationUpdateDeliveryNoteAndProfessionResult>;
    updateDesiredDeliveryDate?: Maybe<MutationUpdateDesiredDeliveryDateResult>;
    updateShippingOption?: Maybe<MutationUpdateShippingOptionResult>;
};

export type MutationAddDiscountCodeToCartArgs = {
    code: Scalars['String'];
    country: BffCountry;
};

export type MutationAddToCartArgs = {
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
    quantity?: InputMaybe<Scalars['Int']>;
    sku: Scalars['Sku'];
};

export type MutationChangeCartArgs = {
    country: BffCountry;
    lineItemId: Scalars['String'];
    quantity: Scalars['Int'];
};

export type MutationChangeCartAddressesArgs = {
    billingAddress: BffCartAddressUpdate;
    country: BffCountry;
    shippingAddress: BffCartAddressUpdate;
};

export type MutationCreateCartShareArgs = {
    country: BffCountry;
};

export type MutationCreateCustomerArgs = {
    country: BffCountry;
    customerData: BffCustomerCreate;
    turnstile: Scalars['String'];
};

export type MutationCreateCustomerAddressArgs = {
    address: BffCustomerAddressUpdate;
    country: BffCountry;
};

export type MutationCreateCustomerShoppingListArgs = {
    country: Scalars['String'];
    lineItems: Array<BffShoppingListLineItemInput>;
};

export type MutationDeleteCustomerAddressArgs = {
    country: BffCountry;
    id: Scalars['String'];
};

export type MutationDeleteCustomerShoppingListItemArgs = {
    country: Scalars['String'];
    id: Scalars['String'];
};

export type MutationInitiatePaymentArgs = {
    country: BffCountry;
    paymentMethod: Scalars['JSON'];
    riskData?: InputMaybe<Scalars['JSON']>;
};

export type MutationRemoveDiscountCodeFromCartArgs = {
    code: Scalars['String'];
    country: BffCountry;
};

export type MutationSplitOrderArgs = {
    country: BffCountry;
};

export type MutationSubmitExpressOrderArgs = {
    country: BffCountry;
    deliveryAddress: BffCustomerAddressUpdate;
    email: Scalars['String'];
    language: BffLanguage;
    paymentDetails: Scalars['JSON'];
    paymentMethod: Scalars['String'];
};

export type MutationSubmitMaterialsOrderArgs = {
    country: BffCountry;
    customerProfession: BffProfession;
    deliveryNote?: InputMaybe<Scalars['String']>;
    language: BffLanguage;
};

export type MutationSubmitOrderArgs = {
    country: BffCountry;
    customerProfession?: InputMaybe<BffProfession>;
    deliveryNote?: InputMaybe<Scalars['String']>;
    language: BffLanguage;
    paymentDetails: Scalars['JSON'];
    paymentMethod: Scalars['String'];
};

export type MutationSubmitPaymentActionArgs = {
    country: BffCountry;
    data: Scalars['JSON'];
    paymentMethod: Scalars['String'];
};

export type MutationUpdateCustomerArgs = {
    country: BffCountry;
    customerData: BffCustomerUpdate;
};

export type MutationUpdateCustomerAddressArgs = {
    address: BffCustomerAddressUpdate;
    country: BffCountry;
    id: Scalars['String'];
};

export type MutationUpdateDeliveryNoteAndProfessionArgs = {
    country: BffCountry;
    customerProfession?: InputMaybe<Scalars['String']>;
    deliveryNote?: InputMaybe<Scalars['String']>;
};

export type MutationUpdateDesiredDeliveryDateArgs = {
    country: BffCountry;
    date?: InputMaybe<Scalars['String']>;
    shippingBatchId: Scalars['String'];
};

export type MutationUpdateShippingOptionArgs = {
    country: BffCountry;
    shippingOptionId: Scalars['String'];
    shippingOptionKey: Scalars['String'];
};

export type MutationAddDiscountCodeToCartResult = BffCartResult | BffProxyError | Error;

export type MutationAddToCartResult = BffCartResult | BffProxyError | Error;

export type MutationChangeCartAddressesResult = BffCartResult | BffProxyError | Error;

export type MutationChangeCartResult = BffCartResult | BffProxyError | Error;

export type MutationCreateCartShareResult = BffCartShareResult | BffProxyError | Error;

export type MutationCreateCustomerAddressResult = BffCustomerAddressUpdateResult | BffProxyError | Error;

export type MutationCreateCustomerResult = BffCustomerModel | BffProxyError | Error;

export type MutationCreateCustomerShoppingListResult = BffProxyError | BffShoppingList | Error;

export type MutationDeleteCustomerAddressResult = BffCustomerAddressDeleteResult | BffProxyError | Error;

export type MutationDeleteCustomerShoppingListItemResult = BffProxyError | BffShoppingList | Error;

export type MutationInitiatePaymentResult = BffProxyError | Error | MutationInitiatePaymentSuccess;

export type MutationInitiatePaymentSuccess = {
    __typename?: 'MutationInitiatePaymentSuccess';
    data: Scalars['JSON'];
};

export type MutationRemoveDiscountCodeFromCartResult = BffCartResult | BffProxyError | Error;

export type MutationSplitOrderResult = BffCartResult | BffProxyError | Error;

export type MutationSubmitExpressOrderResult = BffProxyError | BffSubmitOrderResult | Error;

export type MutationSubmitMaterialsOrderResult = BffProxyError | BffSubmitOrderResult | Error;

export type MutationSubmitOrderResult = BffProxyError | BffSubmitOrderResult | Error;

export type MutationSubmitPaymentActionResult = BffProxyError | BffSubmitOrderResult | Error;

export type MutationUpdateCustomerAddressResult = BffCustomerAddressUpdateResult | BffProxyError | Error;

export type MutationUpdateCustomerResult = BffCustomerModel | BffProxyError | Error;

export type MutationUpdateDeliveryNoteAndProfessionResult = BffCartResult | BffProxyError | Error;

export type MutationUpdateDesiredDeliveryDateResult = BffProxyError | BffUpdateDesiredDeilveryDateResult | Error;

export type MutationUpdateShippingOptionResult = BffCartResult | BffProxyError | Error;

export type Query = {
    __typename?: 'Query';
    checkVatNumber?: Maybe<QueryCheckVatNumberResult>;
    getCart?: Maybe<QueryGetCartResult>;
    getCustomer?: Maybe<QueryGetCustomerResult>;
    getFromPriceBySlug?: Maybe<QueryGetFromPriceBySlugResult>;
    getFromPrices?: Maybe<Array<BffProductPriceModel>>;
    getFromPricesBySlugs?: Maybe<Array<BffProductPriceModel>>;
    getOrder?: Maybe<QueryGetOrderResult>;
    getOrderByCartId?: Maybe<QueryGetOrderByCartIdResult>;
    getProducts: Array<BffProductModel>;
    getProductsAndConfiguration?: Maybe<QueryGetProductsAndConfigurationResult>;
    getShoppingList?: Maybe<QueryGetShoppingListResult>;
    mergeCart?: Maybe<QueryMergeCartResult>;
    mergeShoppingList?: Maybe<QueryMergeShoppingListResult>;
    shareShoppingList?: Maybe<QueryShareShoppingListResult>;
    verifyCustomer?: Maybe<QueryVerifyCustomerResult>;
};

export type QueryCheckVatNumberArgs = {
    country: BffCountry;
    vat: Scalars['String'];
};

export type QueryGetCartArgs = {
    country: BffCountry;
    refresh?: InputMaybe<Scalars['Boolean']>;
};

export type QueryGetCustomerArgs = {
    country: BffCountry;
};

export type QueryGetFromPriceBySlugArgs = {
    market: BffMarket;
    slug: Scalars['String'];
};

export type QueryGetFromPricesArgs = {
    censhareProductIds: Array<Scalars['Int']>;
    market: BffMarket;
};

export type QueryGetFromPricesBySlugsArgs = {
    market: BffMarket;
    slugs: Array<Scalars['String']>;
};

export type QueryGetOrderArgs = {
    country: BffCountry;
    id: Scalars['String'];
};

export type QueryGetOrderByCartIdArgs = {
    cartId: Scalars['String'];
    country: BffCountry;
};

export type QueryGetProductsArgs = {
    dataSource?: InputMaybe<BffDataSource>;
    market: BffMarket;
    products: Array<BffProductInputProduct>;
};

export type QueryGetProductsAndConfigurationArgs = {
    language: BffLanguage;
    market: BffMarket;
    skus: Array<Scalars['String']>;
};

export type QueryGetShoppingListArgs = {
    country: Scalars['String'];
    id?: InputMaybe<Scalars['String']>;
};

export type QueryMergeCartArgs = {
    anonymousToken: Scalars['String'];
    country: BffCountry;
};

export type QueryMergeShoppingListArgs = {
    anonymousToken: Scalars['String'];
    country: BffCountry;
};

export type QueryShareShoppingListArgs = {
    country: BffCountry;
    skus?: InputMaybe<Array<Scalars['String']>>;
};

export type QueryVerifyCustomerArgs = {
    country: BffCountry;
    email: Scalars['String'];
};

export type QueryCheckVatNumberResult = BffProxyError | BffVatResponse | Error;

export type QueryGetCartResult = BffCartResult | BffProxyError | Error;

export type QueryGetCustomerResult = BffCustomerModel | BffProxyError | Error;

export type QueryGetFromPriceBySlugResult = BffProductPriceModel | BffProxyError | Error;

export type QueryGetOrderByCartIdResult = BffOrder | BffProxyError | Error;

export type QueryGetOrderResult = BffOrder | BffProxyError | Error;

export type QueryGetProductsAndConfigurationResult = BffProxyError | Error | QueryGetProductsAndConfigurationSuccess;

export type QueryGetProductsAndConfigurationSuccess = {
    __typename?: 'QueryGetProductsAndConfigurationSuccess';
    data: Array<BffProduct>;
};

export type QueryGetShoppingListResult = BffProxyError | BffShoppingList | Error;

export type QueryMergeCartResult = BffCartResult | BffProxyError | Error;

export type QueryMergeShoppingListResult = BffProxyError | BffShoppingList | Error;

export type QueryShareShoppingListResult = BffProxyError | BffShoppingListShareLink | Error;

export type QueryVerifyCustomerResult = BffCustomerVerifyModel | BffProxyError | Error;

export type CustomerUpdateAddressFragment = {
    __typename?: 'BffCustomerAddressUpdateResult';
    id?: string | null;
    firstName?: string | null;
    city?: string | null;
    country?: string | null;
    phone?: string | null;
    additionalAddressInfo?: string | null;
    lastName?: string | null;
    postalCode?: string | null;
    salutation?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    locality?: string | null;
    defaultShippingAddress?: boolean | null;
};

export type CartAddressFragment = {
    __typename?: 'BffCartAddress';
    id?: string | null;
    firstName?: string | null;
    city?: string | null;
    country?: string | null;
    phone?: string | null;
    additionalAddressInfo?: string | null;
    lastName?: string | null;
    region?: string | null;
    postalCode?: string | null;
    salutation?: string | null;
    streetName?: string | null;
    streetNumber?: string | null;
    locality?: string | null;
    defaultShippingAddress?: boolean | null;
    billing?: boolean | null;
    shipping?: boolean | null;
};

export type CustomerAddressFragment = {
    __typename?: 'BffCustomerAddress';
    id: string;
    city: string;
    country: string;
    phone?: string | null;
    additionalAddressInfo?: string | null;
    firstName: string;
    lastName: string;
    region?: string | null;
    postalCode: string;
    salutation: string;
    streetName: string;
    streetNumber: string;
    locality?: string | null;
    defaultShippingAddress: boolean;
    billing?: boolean | null;
    shipping?: boolean | null;
};

export type CartInfoWithoutPaymentFragment = {
    __typename?: 'BffCartResult';
    id: string;
    version?: string | null;
    count: number;
    anayltics?: any | null;
    canSplitOrder: boolean;
    isOrderSplit: boolean;
    showReturnNote: boolean;
    discountCode?: string | null;
    isBlocked: boolean;
    skipPayment: boolean;
    custom?: any | null;
    totals: Array<{
        __typename?: 'BffCartResultTotalPrice';
        formatted: string;
        rate?: string | null;
        key: string;
        raw?: number | null;
        currencyCode?: string | null;
    }>;
    messages: Array<{ __typename?: 'BffCartResultMessage'; type: string; sku?: string | null; message: string }>;
    paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
    cartItems: Array<{
        __typename?: 'BffCartResultCartItem';
        id: string;
        lineItems?: Array<{
            __typename?: 'BffCartResultItem';
            id: string;
            sku: string;
            name: string;
            type: string;
            quantity: number;
            articleNo: string;
            showQuantity: boolean;
            isConfigurable: boolean;
            showReturnNote: boolean;
            orderLimit: number;
            image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
            totalPrice: {
                __typename?: 'BffProductPrice';
                formatted?: string | null;
                raw?: number | null;
                currencyCode?: string | null;
            };
            messages: Array<{
                __typename?: 'BffCartResultMessage';
                type: string;
                sku?: string | null;
                message: string;
            }>;
        }> | null;
    }>;
};

export type CartInfoCheckoutFragment = {
    __typename?: 'BffCartResult';
    id: string;
    count: number;
    version?: string | null;
    anayltics?: any | null;
    canSplitOrder: boolean;
    isOrderSplit: boolean;
    showReturnNote: boolean;
    discountCode?: string | null;
    isBlocked: boolean;
    skipPayment: boolean;
    custom?: any | null;
    payment?: any | null;
    totals: Array<{
        __typename?: 'BffCartResultTotalPrice';
        formatted: string;
        rate?: string | null;
        key: string;
        raw?: number | null;
        currencyCode?: string | null;
    }>;
    messages: Array<{ __typename?: 'BffCartResultMessage'; type: string; sku?: string | null; message: string }>;
    paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
    shippingAddress?: {
        __typename?: 'BffCartAddress';
        email?: string | null;
        id?: string | null;
        firstName?: string | null;
        city?: string | null;
        country?: string | null;
        phone?: string | null;
        additionalAddressInfo?: string | null;
        lastName?: string | null;
        region?: string | null;
        postalCode?: string | null;
        salutation?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        locality?: string | null;
        defaultShippingAddress?: boolean | null;
        billing?: boolean | null;
        shipping?: boolean | null;
    } | null;
    billingAddress?: {
        __typename?: 'BffCartAddress';
        email?: string | null;
        id?: string | null;
        firstName?: string | null;
        city?: string | null;
        country?: string | null;
        phone?: string | null;
        additionalAddressInfo?: string | null;
        lastName?: string | null;
        region?: string | null;
        postalCode?: string | null;
        salutation?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        locality?: string | null;
        defaultShippingAddress?: boolean | null;
        billing?: boolean | null;
        shipping?: boolean | null;
    } | null;
    cartItems: Array<{
        __typename?: 'BffCartResultCartItem';
        id: string;
        lineItems?: Array<{
            __typename?: 'BffCartResultItem';
            id: string;
            sku: string;
            name: string;
            type: string;
            quantity: number;
            articleNo: string;
            showQuantity: boolean;
            isConfigurable: boolean;
            showReturnNote: boolean;
            orderLimit: number;
            delivery?: {
                __typename?: 'BffCartResultDelivery';
                weekRange?: string | null;
                deliveryStartDate?: string | null;
                deliveryEndDate?: string | null;
                calendarStartDate?: string | null;
                calendarEndDate?: string | null;
                desiredDeliveryDate?: string | null;
            } | null;
            image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
            totalPrice: {
                __typename?: 'BffProductPrice';
                formatted?: string | null;
                raw?: number | null;
                currencyCode?: string | null;
            };
            messages: Array<{
                __typename?: 'BffCartResultMessage';
                type: string;
                sku?: string | null;
                message: string;
            }>;
        }> | null;
        delivery?: {
            __typename?: 'BffCartResultCartItemDelivery';
            weekRange: string;
            deliveryStartDate: string;
            deliveryEndDate: string;
            calendarStartDate: string;
            calendarEndDate: string;
            desiredDeliveryDate: string;
        } | null;
        shippingCost?: {
            __typename?: 'BffCartResultShippingCost';
            id?: string | null;
            shippingType: string;
            price: {
                __typename?: 'BffCartResultShippingCostPrice';
                formatted: string;
                raw?: number | null;
                currencyCode?: string | null;
            };
        } | null;
        shippingOption?: {
            __typename?: 'BffCartResultShippingOption';
            id?: string | null;
            items: Array<{
                __typename?: 'BffCartResultShippingOptionItem';
                key: string;
                description: string;
                selected: boolean;
                price: {
                    __typename?: 'BffCartResultShippingCostPrice';
                    formatted: string;
                    raw?: number | null;
                    currencyCode?: string | null;
                };
            }>;
        } | null;
    }>;
};

export type CartInfoCartFragment = {
    __typename?: 'BffCartResult';
    id: string;
    version?: string | null;
    count: number;
    anayltics?: any | null;
    canSplitOrder: boolean;
    isOrderSplit: boolean;
    showReturnNote: boolean;
    discountCode?: string | null;
    isBlocked: boolean;
    skipPayment: boolean;
    custom?: any | null;
    payment?: any | null;
    totals: Array<{
        __typename?: 'BffCartResultTotalPrice';
        formatted: string;
        rate?: string | null;
        key: string;
        raw?: number | null;
        currencyCode?: string | null;
    }>;
    messages: Array<{ __typename?: 'BffCartResultMessage'; type: string; sku?: string | null; message: string }>;
    paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
    cartItems: Array<{
        __typename?: 'BffCartResultCartItem';
        id: string;
        lineItems?: Array<{
            __typename?: 'BffCartResultItem';
            id: string;
            sku: string;
            name: string;
            type: string;
            quantity: number;
            articleNo: string;
            showQuantity: boolean;
            isConfigurable: boolean;
            showReturnNote: boolean;
            orderLimit: number;
            image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
            totalPrice: {
                __typename?: 'BffProductPrice';
                formatted?: string | null;
                raw?: number | null;
                currencyCode?: string | null;
            };
            messages: Array<{
                __typename?: 'BffCartResultMessage';
                type: string;
                sku?: string | null;
                message: string;
            }>;
        }> | null;
        shippingOption?: {
            __typename?: 'BffCartResultShippingOption';
            id?: string | null;
            items: Array<{
                __typename?: 'BffCartResultShippingOptionItem';
                key: string;
                description: string;
                selected: boolean;
                price: {
                    __typename?: 'BffCartResultShippingCostPrice';
                    formatted: string;
                    raw?: number | null;
                    currencyCode?: string | null;
                };
            }>;
        } | null;
    }>;
};

export type AddToCartMutationVariables = Exact<{
    sku: Scalars['Sku'];
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
    quantity?: InputMaybe<Scalars['Int']>;
}>;

export type AddToCartMutation = {
    __typename?: 'Mutation';
    addToCart:
        | {
              __typename: 'BffCartResult';
              id: string;
              version?: string | null;
              count: number;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  count: number;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string };
};

export type AddToCartWithoutPaymentMutationVariables = Exact<{
    sku: Scalars['Sku'];
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
    quantity?: InputMaybe<Scalars['Int']>;
}>;

export type AddToCartWithoutPaymentMutation = {
    __typename?: 'Mutation';
    addToCart:
        | {
              __typename: 'BffCartResult';
              id: string;
              version?: string | null;
              count: number;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  count: number;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string };
};

export type ChangeCartMutationVariables = Exact<{
    lineItemId: Scalars['String'];
    quantity: Scalars['Int'];
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
}>;

export type ChangeCartMutation = {
    __typename?: 'Mutation';
    changeCart:
        | {
              __typename: 'BffCartResult';
              id: string;
              version?: string | null;
              count: number;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  version?: string | null;
                  count: number;
                  anayltics?: any | null;
                  canSplitOrder: boolean;
                  isOrderSplit: boolean;
                  showReturnNote: boolean;
                  discountCode?: string | null;
                  isBlocked: boolean;
                  skipPayment: boolean;
                  custom?: any | null;
                  payment?: any | null;
                  totals: Array<{
                      __typename?: 'BffCartResultTotalPrice';
                      formatted: string;
                      rate?: string | null;
                      key: string;
                      raw?: number | null;
                      currencyCode?: string | null;
                  }>;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
                  paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
                  cartItems: Array<{
                      __typename?: 'BffCartResultCartItem';
                      id: string;
                      lineItems?: Array<{
                          __typename?: 'BffCartResultItem';
                          id: string;
                          sku: string;
                          name: string;
                          type: string;
                          quantity: number;
                          articleNo: string;
                          showQuantity: boolean;
                          isConfigurable: boolean;
                          showReturnNote: boolean;
                          orderLimit: number;
                          image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                          totalPrice: {
                              __typename?: 'BffProductPrice';
                              formatted?: string | null;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                          messages: Array<{
                              __typename?: 'BffCartResultMessage';
                              type: string;
                              sku?: string | null;
                              message: string;
                          }>;
                      }> | null;
                      shippingOption?: {
                          __typename?: 'BffCartResultShippingOption';
                          id?: string | null;
                          items: Array<{
                              __typename?: 'BffCartResultShippingOptionItem';
                              key: string;
                              description: string;
                              selected: boolean;
                              price: {
                                  __typename?: 'BffCartResultShippingCostPrice';
                                  formatted: string;
                                  raw?: number | null;
                                  currencyCode?: string | null;
                              };
                          }>;
                      } | null;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string };
};

export type SplitOrderMutationVariables = Exact<{
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
}>;

export type SplitOrderMutation = {
    __typename?: 'Mutation';
    splitOrder:
        | {
              __typename: 'BffCartResult';
              id: string;
              count: number;
              version?: string | null;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              shippingAddress?: {
                  __typename?: 'BffCartAddress';
                  email?: string | null;
                  id?: string | null;
                  firstName?: string | null;
                  city?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  lastName?: string | null;
                  region?: string | null;
                  postalCode?: string | null;
                  salutation?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  locality?: string | null;
                  defaultShippingAddress?: boolean | null;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              } | null;
              billingAddress?: {
                  __typename?: 'BffCartAddress';
                  email?: string | null;
                  id?: string | null;
                  firstName?: string | null;
                  city?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  lastName?: string | null;
                  region?: string | null;
                  postalCode?: string | null;
                  salutation?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  locality?: string | null;
                  defaultShippingAddress?: boolean | null;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      delivery?: {
                          __typename?: 'BffCartResultDelivery';
                          weekRange?: string | null;
                          deliveryStartDate?: string | null;
                          deliveryEndDate?: string | null;
                          calendarStartDate?: string | null;
                          calendarEndDate?: string | null;
                          desiredDeliveryDate?: string | null;
                      } | null;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  delivery?: {
                      __typename?: 'BffCartResultCartItemDelivery';
                      weekRange: string;
                      deliveryStartDate: string;
                      deliveryEndDate: string;
                      calendarStartDate: string;
                      calendarEndDate: string;
                      desiredDeliveryDate: string;
                  } | null;
                  shippingCost?: {
                      __typename?: 'BffCartResultShippingCost';
                      id?: string | null;
                      shippingType: string;
                      price: {
                          __typename?: 'BffCartResultShippingCostPrice';
                          formatted: string;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                  } | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  count: number;
                  version?: string | null;
                  anayltics?: any | null;
                  canSplitOrder: boolean;
                  isOrderSplit: boolean;
                  showReturnNote: boolean;
                  discountCode?: string | null;
                  isBlocked: boolean;
                  skipPayment: boolean;
                  custom?: any | null;
                  payment?: any | null;
                  totals: Array<{
                      __typename?: 'BffCartResultTotalPrice';
                      formatted: string;
                      rate?: string | null;
                      key: string;
                      raw?: number | null;
                      currencyCode?: string | null;
                  }>;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
                  paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
                  shippingAddress?: {
                      __typename?: 'BffCartAddress';
                      email?: string | null;
                      id?: string | null;
                      firstName?: string | null;
                      city?: string | null;
                      country?: string | null;
                      phone?: string | null;
                      additionalAddressInfo?: string | null;
                      lastName?: string | null;
                      region?: string | null;
                      postalCode?: string | null;
                      salutation?: string | null;
                      streetName?: string | null;
                      streetNumber?: string | null;
                      locality?: string | null;
                      defaultShippingAddress?: boolean | null;
                      billing?: boolean | null;
                      shipping?: boolean | null;
                  } | null;
                  billingAddress?: {
                      __typename?: 'BffCartAddress';
                      email?: string | null;
                      id?: string | null;
                      firstName?: string | null;
                      city?: string | null;
                      country?: string | null;
                      phone?: string | null;
                      additionalAddressInfo?: string | null;
                      lastName?: string | null;
                      region?: string | null;
                      postalCode?: string | null;
                      salutation?: string | null;
                      streetName?: string | null;
                      streetNumber?: string | null;
                      locality?: string | null;
                      defaultShippingAddress?: boolean | null;
                      billing?: boolean | null;
                      shipping?: boolean | null;
                  } | null;
                  cartItems: Array<{
                      __typename?: 'BffCartResultCartItem';
                      id: string;
                      lineItems?: Array<{
                          __typename?: 'BffCartResultItem';
                          id: string;
                          sku: string;
                          name: string;
                          type: string;
                          quantity: number;
                          articleNo: string;
                          showQuantity: boolean;
                          isConfigurable: boolean;
                          showReturnNote: boolean;
                          orderLimit: number;
                          delivery?: {
                              __typename?: 'BffCartResultDelivery';
                              weekRange?: string | null;
                              deliveryStartDate?: string | null;
                              deliveryEndDate?: string | null;
                              calendarStartDate?: string | null;
                              calendarEndDate?: string | null;
                              desiredDeliveryDate?: string | null;
                          } | null;
                          image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                          totalPrice: {
                              __typename?: 'BffProductPrice';
                              formatted?: string | null;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                          messages: Array<{
                              __typename?: 'BffCartResultMessage';
                              type: string;
                              sku?: string | null;
                              message: string;
                          }>;
                      }> | null;
                      delivery?: {
                          __typename?: 'BffCartResultCartItemDelivery';
                          weekRange: string;
                          deliveryStartDate: string;
                          deliveryEndDate: string;
                          calendarStartDate: string;
                          calendarEndDate: string;
                          desiredDeliveryDate: string;
                      } | null;
                      shippingCost?: {
                          __typename?: 'BffCartResultShippingCost';
                          id?: string | null;
                          shippingType: string;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      } | null;
                      shippingOption?: {
                          __typename?: 'BffCartResultShippingOption';
                          id?: string | null;
                          items: Array<{
                              __typename?: 'BffCartResultShippingOptionItem';
                              key: string;
                              description: string;
                              selected: boolean;
                              price: {
                                  __typename?: 'BffCartResultShippingCostPrice';
                                  formatted: string;
                                  raw?: number | null;
                                  currencyCode?: string | null;
                              };
                          }>;
                      } | null;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string };
};

export type ChangeCartAddressesMutationVariables = Exact<{
    shippingAddress: BffCartAddressUpdate;
    billingAddress: BffCartAddressUpdate;
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
}>;

export type ChangeCartAddressesMutation = {
    __typename?: 'Mutation';
    changeCartAddresses:
        | {
              __typename: 'BffCartResult';
              id: string;
              count: number;
              version?: string | null;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              shippingAddress?: {
                  __typename?: 'BffCartAddress';
                  email?: string | null;
                  id?: string | null;
                  firstName?: string | null;
                  city?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  lastName?: string | null;
                  region?: string | null;
                  postalCode?: string | null;
                  salutation?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  locality?: string | null;
                  defaultShippingAddress?: boolean | null;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              } | null;
              billingAddress?: {
                  __typename?: 'BffCartAddress';
                  email?: string | null;
                  id?: string | null;
                  firstName?: string | null;
                  city?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  lastName?: string | null;
                  region?: string | null;
                  postalCode?: string | null;
                  salutation?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  locality?: string | null;
                  defaultShippingAddress?: boolean | null;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      delivery?: {
                          __typename?: 'BffCartResultDelivery';
                          weekRange?: string | null;
                          deliveryStartDate?: string | null;
                          deliveryEndDate?: string | null;
                          calendarStartDate?: string | null;
                          calendarEndDate?: string | null;
                          desiredDeliveryDate?: string | null;
                      } | null;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  delivery?: {
                      __typename?: 'BffCartResultCartItemDelivery';
                      weekRange: string;
                      deliveryStartDate: string;
                      deliveryEndDate: string;
                      calendarStartDate: string;
                      calendarEndDate: string;
                      desiredDeliveryDate: string;
                  } | null;
                  shippingCost?: {
                      __typename?: 'BffCartResultShippingCost';
                      id?: string | null;
                      shippingType: string;
                      price: {
                          __typename?: 'BffCartResultShippingCostPrice';
                          formatted: string;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                  } | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: { __typename?: 'BffCartResult'; id: string } | null;
          }
        | { __typename: 'Error'; message: string };
};

export type UpdateShippingOptionMutationVariables = Exact<{
    shippingOptionId: Scalars['String'];
    shippingOptionKey: Scalars['String'];
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
}>;

export type UpdateShippingOptionMutation = {
    __typename?: 'Mutation';
    updateShippingOption?:
        | {
              __typename: 'BffCartResult';
              id: string;
              count: number;
              version?: string | null;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              shippingAddress?: {
                  __typename?: 'BffCartAddress';
                  email?: string | null;
                  id?: string | null;
                  firstName?: string | null;
                  city?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  lastName?: string | null;
                  region?: string | null;
                  postalCode?: string | null;
                  salutation?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  locality?: string | null;
                  defaultShippingAddress?: boolean | null;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              } | null;
              billingAddress?: {
                  __typename?: 'BffCartAddress';
                  email?: string | null;
                  id?: string | null;
                  firstName?: string | null;
                  city?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  lastName?: string | null;
                  region?: string | null;
                  postalCode?: string | null;
                  salutation?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  locality?: string | null;
                  defaultShippingAddress?: boolean | null;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      delivery?: {
                          __typename?: 'BffCartResultDelivery';
                          weekRange?: string | null;
                          deliveryStartDate?: string | null;
                          deliveryEndDate?: string | null;
                          calendarStartDate?: string | null;
                          calendarEndDate?: string | null;
                          desiredDeliveryDate?: string | null;
                      } | null;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  delivery?: {
                      __typename?: 'BffCartResultCartItemDelivery';
                      weekRange: string;
                      deliveryStartDate: string;
                      deliveryEndDate: string;
                      calendarStartDate: string;
                      calendarEndDate: string;
                      desiredDeliveryDate: string;
                  } | null;
                  shippingCost?: {
                      __typename?: 'BffCartResultShippingCost';
                      id?: string | null;
                      shippingType: string;
                      price: {
                          __typename?: 'BffCartResultShippingCostPrice';
                          formatted: string;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                  } | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: { __typename?: 'BffCartResult'; id: string } | null;
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type UpdateDesiredDeliveryDateMutationVariables = Exact<{
    shippingBatchId: Scalars['String'];
    date: Scalars['String'];
    country: BffCountry;
}>;

export type UpdateDesiredDeliveryDateMutation = {
    __typename?: 'Mutation';
    updateDesiredDeliveryDate?:
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: { __typename?: 'BffCartResult'; id: string } | null;
          }
        | {
              __typename: 'BffUpdateDesiredDeilveryDateResult';
              shippingBatchId?: string | null;
              desiredDateSelected?: boolean | null;
              userSelectedDeliveryStartDate?: string | null;
              userSelectedDeliveryEndDate?: string | null;
              deliveryTime?: string | null;
              calendarStartDate?: string | null;
              calendarEndDate?: string | null;
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type InitiatePaymentMutationVariables = Exact<{
    country: BffCountry;
    paymentMethod: Scalars['JSON'];
    riskData?: InputMaybe<Scalars['JSON']>;
}>;

export type InitiatePaymentMutation = {
    __typename?: 'Mutation';
    initiatePayment?:
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: { __typename?: 'BffCartResult'; id: string } | null;
          }
        | { __typename: 'Error'; message: string }
        | { __typename: 'MutationInitiatePaymentSuccess'; data: any }
        | null;
};

export type BffSubmitOrderResultFragmentFragment = {
    __typename?: 'BffSubmitOrderResult';
    cartId: string;
    success: boolean;
    payment: {
        __typename?: 'BffSubmitOrderResultPayment';
        action?: any | null;
        pspReference?: string | null;
        refusalReason?: string | null;
        refusalReasonCode?: string | null;
    };
};

export type SubmitExpressOrderMutationVariables = Exact<{
    country: BffCountry;
    language: BffLanguage;
    deliveryAddress: BffCustomerAddressUpdate;
    email: Scalars['String'];
    paymentMethod: Scalars['String'];
    paymentDetails: Scalars['JSON'];
}>;

export type SubmitExpressOrderMutation = {
    __typename?: 'Mutation';
    submitExpressOrder?:
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: { __typename?: 'BffCartResult'; id: string } | null;
          }
        | {
              __typename: 'BffSubmitOrderResult';
              cartId: string;
              success: boolean;
              payment: {
                  __typename?: 'BffSubmitOrderResultPayment';
                  action?: any | null;
                  pspReference?: string | null;
                  refusalReason?: string | null;
                  refusalReasonCode?: string | null;
              };
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type SubmitOrderMutationVariables = Exact<{
    country: BffCountry;
    language: BffLanguage;
    paymentMethod: Scalars['String'];
    paymentDetails: Scalars['JSON'];
    deliveryNote?: InputMaybe<Scalars['String']>;
    customerProfession?: InputMaybe<BffProfession>;
}>;

export type SubmitOrderMutation = {
    __typename?: 'Mutation';
    submitOrder?:
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: { __typename?: 'BffCartResult'; id: string } | null;
          }
        | {
              __typename: 'BffSubmitOrderResult';
              cartId: string;
              success: boolean;
              payment: {
                  __typename?: 'BffSubmitOrderResultPayment';
                  action?: any | null;
                  pspReference?: string | null;
                  refusalReason?: string | null;
                  refusalReasonCode?: string | null;
              };
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type SubmitMaterialsOrderMutationVariables = Exact<{
    country: BffCountry;
    language: BffLanguage;
    deliveryNote?: InputMaybe<Scalars['String']>;
    customerProfession: BffProfession;
}>;

export type SubmitMaterialsOrderMutation = {
    __typename?: 'Mutation';
    submitMaterialsOrder?:
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: { __typename?: 'BffCartResult'; id: string } | null;
          }
        | {
              __typename: 'BffSubmitOrderResult';
              cartId: string;
              success: boolean;
              payment: {
                  __typename?: 'BffSubmitOrderResultPayment';
                  action?: any | null;
                  pspReference?: string | null;
                  refusalReason?: string | null;
                  refusalReasonCode?: string | null;
              };
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type SubmitPaymentActionMutationVariables = Exact<{
    country: BffCountry;
    paymentMethod: Scalars['String'];
    data: Scalars['JSON'];
}>;

export type SubmitPaymentActionMutation = {
    __typename?: 'Mutation';
    submitPaymentAction?:
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: { __typename?: 'BffCartResult'; id: string } | null;
          }
        | {
              __typename: 'BffSubmitOrderResult';
              cartId: string;
              success: boolean;
              payment: {
                  __typename?: 'BffSubmitOrderResultPayment';
                  action?: any | null;
                  pspReference?: string | null;
                  refusalReason?: string | null;
                  refusalReasonCode?: string | null;
              };
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type AddDiscountCodeToCartMutationVariables = Exact<{
    code: Scalars['String'];
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
}>;

export type AddDiscountCodeToCartMutation = {
    __typename?: 'Mutation';
    addDiscountCodeToCart:
        | {
              __typename: 'BffCartResult';
              id: string;
              version?: string | null;
              count: number;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  version?: string | null;
                  count: number;
                  anayltics?: any | null;
                  canSplitOrder: boolean;
                  isOrderSplit: boolean;
                  showReturnNote: boolean;
                  discountCode?: string | null;
                  isBlocked: boolean;
                  skipPayment: boolean;
                  custom?: any | null;
                  payment?: any | null;
                  totals: Array<{
                      __typename?: 'BffCartResultTotalPrice';
                      formatted: string;
                      rate?: string | null;
                      key: string;
                      raw?: number | null;
                      currencyCode?: string | null;
                  }>;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
                  paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
                  cartItems: Array<{
                      __typename?: 'BffCartResultCartItem';
                      id: string;
                      lineItems?: Array<{
                          __typename?: 'BffCartResultItem';
                          id: string;
                          sku: string;
                          name: string;
                          type: string;
                          quantity: number;
                          articleNo: string;
                          showQuantity: boolean;
                          isConfigurable: boolean;
                          showReturnNote: boolean;
                          orderLimit: number;
                          image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                          totalPrice: {
                              __typename?: 'BffProductPrice';
                              formatted?: string | null;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                          messages: Array<{
                              __typename?: 'BffCartResultMessage';
                              type: string;
                              sku?: string | null;
                              message: string;
                          }>;
                      }> | null;
                      shippingOption?: {
                          __typename?: 'BffCartResultShippingOption';
                          id?: string | null;
                          items: Array<{
                              __typename?: 'BffCartResultShippingOptionItem';
                              key: string;
                              description: string;
                              selected: boolean;
                              price: {
                                  __typename?: 'BffCartResultShippingCostPrice';
                                  formatted: string;
                                  raw?: number | null;
                                  currencyCode?: string | null;
                              };
                          }>;
                      } | null;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string };
};

export type RemoveDiscountCodeFromCartMutationVariables = Exact<{
    code: Scalars['String'];
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
}>;

export type RemoveDiscountCodeFromCartMutation = {
    __typename?: 'Mutation';
    removeDiscountCodeFromCart:
        | {
              __typename: 'BffCartResult';
              id: string;
              version?: string | null;
              count: number;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  version?: string | null;
                  count: number;
                  anayltics?: any | null;
                  canSplitOrder: boolean;
                  isOrderSplit: boolean;
                  showReturnNote: boolean;
                  discountCode?: string | null;
                  isBlocked: boolean;
                  skipPayment: boolean;
                  custom?: any | null;
                  payment?: any | null;
                  totals: Array<{
                      __typename?: 'BffCartResultTotalPrice';
                      formatted: string;
                      rate?: string | null;
                      key: string;
                      raw?: number | null;
                      currencyCode?: string | null;
                  }>;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
                  paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
                  cartItems: Array<{
                      __typename?: 'BffCartResultCartItem';
                      id: string;
                      lineItems?: Array<{
                          __typename?: 'BffCartResultItem';
                          id: string;
                          sku: string;
                          name: string;
                          type: string;
                          quantity: number;
                          articleNo: string;
                          showQuantity: boolean;
                          isConfigurable: boolean;
                          showReturnNote: boolean;
                          orderLimit: number;
                          image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                          totalPrice: {
                              __typename?: 'BffProductPrice';
                              formatted?: string | null;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                          messages: Array<{
                              __typename?: 'BffCartResultMessage';
                              type: string;
                              sku?: string | null;
                              message: string;
                          }>;
                      }> | null;
                      shippingOption?: {
                          __typename?: 'BffCartResultShippingOption';
                          id?: string | null;
                          items: Array<{
                              __typename?: 'BffCartResultShippingOptionItem';
                              key: string;
                              description: string;
                              selected: boolean;
                              price: {
                                  __typename?: 'BffCartResultShippingCostPrice';
                                  formatted: string;
                                  raw?: number | null;
                                  currencyCode?: string | null;
                              };
                          }>;
                      } | null;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string };
};

export type CreateCartShareMutationVariables = Exact<{
    country: BffCountry;
}>;

export type CreateCartShareMutation = {
    __typename?: 'Mutation';
    createCartShare:
        | { __typename: 'BffCartShareResult'; url?: string | null }
        | { __typename: 'BffProxyError'; status: number; message: string; traceId: string }
        | { __typename: 'Error'; message: string };
};

export type CreateCustomerMutationVariables = Exact<{
    country: BffCountry;
    turnstile: Scalars['String'];
    customerData: BffCustomerCreate;
}>;

export type CreateCustomerMutation = {
    __typename?: 'Mutation';
    createCustomer:
        | {
              __typename: 'BffCustomerModel';
              email: string;
              firstName: string;
              lastName: string;
              salutation: string;
              title?: string | null;
              companyName?: string | null;
              customerType: string;
              vatId?: string | null;
              vatIdCountry?: string | null;
              preferredLanguage?: string | null;
              preferredCountry?: string | null;
          }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string };
};

export type UpdateCustomerMutationVariables = Exact<{
    country: BffCountry;
    customerData: BffCustomerUpdate;
}>;

export type UpdateCustomerMutation = {
    __typename?: 'Mutation';
    updateCustomer:
        | {
              __typename: 'BffCustomerModel';
              customerNumber: string;
              customerType: string;
              email: string;
              firstName: string;
              lastName: string;
              salutation: string;
              title?: string | null;
              companyName?: string | null;
              vatId?: string | null;
              vatIdCountry?: string | null;
              preferredLanguage?: string | null;
              preferredCountry?: string | null;
              addressBook: Array<{
                  __typename?: 'BffCustomerAddress';
                  id: string;
                  city: string;
                  country: string;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  firstName: string;
                  lastName: string;
                  region?: string | null;
                  postalCode: string;
                  salutation: string;
                  streetName: string;
                  streetNumber: string;
                  locality?: string | null;
                  defaultShippingAddress: boolean;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              }>;
          }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string };
};

export type UpdateCustomerAddressMutationVariables = Exact<{
    id: Scalars['String'];
    country: BffCountry;
    address: BffCustomerAddressUpdate;
}>;

export type UpdateCustomerAddressMutation = {
    __typename?: 'Mutation';
    updateCustomerAddress:
        | {
              __typename: 'BffCustomerAddressUpdateResult';
              id?: string | null;
              firstName?: string | null;
              city?: string | null;
              country?: string | null;
              phone?: string | null;
              additionalAddressInfo?: string | null;
              lastName?: string | null;
              postalCode?: string | null;
              salutation?: string | null;
              streetName?: string | null;
              streetNumber?: string | null;
              locality?: string | null;
              defaultShippingAddress?: boolean | null;
          }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string };
};

export type CreateCustomerAddressMutationVariables = Exact<{
    country: BffCountry;
    address: BffCustomerAddressUpdate;
}>;

export type CreateCustomerAddressMutation = {
    __typename?: 'Mutation';
    createCustomerAddress:
        | {
              __typename: 'BffCustomerAddressUpdateResult';
              id?: string | null;
              firstName?: string | null;
              city?: string | null;
              country?: string | null;
              phone?: string | null;
              additionalAddressInfo?: string | null;
              lastName?: string | null;
              postalCode?: string | null;
              salutation?: string | null;
              streetName?: string | null;
              streetNumber?: string | null;
              locality?: string | null;
              defaultShippingAddress?: boolean | null;
          }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string };
};

export type DeleteCustomerAddressMutationVariables = Exact<{
    country: BffCountry;
    id: Scalars['String'];
}>;

export type DeleteCustomerAddressMutation = {
    __typename?: 'Mutation';
    deleteCustomerAddress:
        | { __typename: 'BffCustomerAddressDeleteResult'; id: string }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string };
};

export type CreateCustomerShoppingListMutationVariables = Exact<{
    country: Scalars['String'];
    lineItems: Array<BffShoppingListLineItemInput> | BffShoppingListLineItemInput;
}>;

export type CreateCustomerShoppingListMutation = {
    __typename?: 'Mutation';
    createCustomerShoppingList:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | {
              __typename: 'BffShoppingList';
              id: string;
              isCartShare: boolean;
              lineItems: Array<{
                  __typename?: 'BffShoppingListLineItem';
                  id: string;
                  sku: string;
                  productType: BffShoppingListLineItemProductTypeEnum;
                  name: string;
                  censhareId: string;
                  isSellable: boolean;
                  link?: { __typename?: 'BffLink'; href: string; name?: string | null; title?: string | null } | null;
                  image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
              }>;
          }
        | { __typename: 'Error'; message: string };
};

export type DeleteCustomerShoppingListItemMutationVariables = Exact<{
    country: Scalars['String'];
    id: Scalars['String'];
}>;

export type DeleteCustomerShoppingListItemMutation = {
    __typename?: 'Mutation';
    deleteCustomerShoppingListItem:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | {
              __typename: 'BffShoppingList';
              id: string;
              isCartShare: boolean;
              lineItems: Array<{
                  __typename?: 'BffShoppingListLineItem';
                  id: string;
                  sku: string;
                  productType: BffShoppingListLineItemProductTypeEnum;
                  name: string;
                  censhareId: string;
                  isSellable: boolean;
                  link?: { __typename?: 'BffLink'; href: string; name?: string | null; title?: string | null } | null;
                  image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
              }>;
          }
        | { __typename: 'Error'; message: string };
};

export type GetCartQueryVariables = Exact<{
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
    refresh?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCartQuery = {
    __typename?: 'Query';
    getCart?:
        | {
              __typename: 'BffCartResult';
              id: string;
              version?: string | null;
              count: number;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  version?: string | null;
                  count: number;
                  anayltics?: any | null;
                  canSplitOrder: boolean;
                  isOrderSplit: boolean;
                  showReturnNote: boolean;
                  discountCode?: string | null;
                  isBlocked: boolean;
                  skipPayment: boolean;
                  custom?: any | null;
                  payment?: any | null;
                  totals: Array<{
                      __typename?: 'BffCartResultTotalPrice';
                      formatted: string;
                      rate?: string | null;
                      key: string;
                      raw?: number | null;
                      currencyCode?: string | null;
                  }>;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
                  paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
                  cartItems: Array<{
                      __typename?: 'BffCartResultCartItem';
                      id: string;
                      lineItems?: Array<{
                          __typename?: 'BffCartResultItem';
                          id: string;
                          sku: string;
                          name: string;
                          type: string;
                          quantity: number;
                          articleNo: string;
                          showQuantity: boolean;
                          isConfigurable: boolean;
                          showReturnNote: boolean;
                          orderLimit: number;
                          image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                          totalPrice: {
                              __typename?: 'BffProductPrice';
                              formatted?: string | null;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                          messages: Array<{
                              __typename?: 'BffCartResultMessage';
                              type: string;
                              sku?: string | null;
                              message: string;
                          }>;
                      }> | null;
                      shippingOption?: {
                          __typename?: 'BffCartResultShippingOption';
                          id?: string | null;
                          items: Array<{
                              __typename?: 'BffCartResultShippingOptionItem';
                              key: string;
                              description: string;
                              selected: boolean;
                              price: {
                                  __typename?: 'BffCartResultShippingCostPrice';
                                  formatted: string;
                                  raw?: number | null;
                                  currencyCode?: string | null;
                              };
                          }>;
                      } | null;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type GetCartCheckoutQueryVariables = Exact<{
    country: BffCountry;
    language?: InputMaybe<BffLanguage>;
    refresh?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetCartCheckoutQuery = {
    __typename?: 'Query';
    getCart?:
        | {
              __typename: 'BffCartResult';
              id: string;
              count: number;
              version?: string | null;
              anayltics?: any | null;
              canSplitOrder: boolean;
              isOrderSplit: boolean;
              showReturnNote: boolean;
              discountCode?: string | null;
              isBlocked: boolean;
              skipPayment: boolean;
              custom?: any | null;
              payment?: any | null;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  formatted: string;
                  rate?: string | null;
                  key: string;
                  raw?: number | null;
                  currencyCode?: string | null;
              }>;
              messages: Array<{
                  __typename?: 'BffCartResultMessage';
                  type: string;
                  sku?: string | null;
                  message: string;
              }>;
              paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
              shippingAddress?: {
                  __typename?: 'BffCartAddress';
                  email?: string | null;
                  id?: string | null;
                  firstName?: string | null;
                  city?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  lastName?: string | null;
                  region?: string | null;
                  postalCode?: string | null;
                  salutation?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  locality?: string | null;
                  defaultShippingAddress?: boolean | null;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              } | null;
              billingAddress?: {
                  __typename?: 'BffCartAddress';
                  email?: string | null;
                  id?: string | null;
                  firstName?: string | null;
                  city?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  lastName?: string | null;
                  region?: string | null;
                  postalCode?: string | null;
                  salutation?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  locality?: string | null;
                  defaultShippingAddress?: boolean | null;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              } | null;
              cartItems: Array<{
                  __typename?: 'BffCartResultCartItem';
                  id: string;
                  lineItems?: Array<{
                      __typename?: 'BffCartResultItem';
                      id: string;
                      sku: string;
                      name: string;
                      type: string;
                      quantity: number;
                      articleNo: string;
                      showQuantity: boolean;
                      isConfigurable: boolean;
                      showReturnNote: boolean;
                      orderLimit: number;
                      delivery?: {
                          __typename?: 'BffCartResultDelivery';
                          weekRange?: string | null;
                          deliveryStartDate?: string | null;
                          deliveryEndDate?: string | null;
                          calendarStartDate?: string | null;
                          calendarEndDate?: string | null;
                          desiredDeliveryDate?: string | null;
                      } | null;
                      image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          formatted?: string | null;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                      messages: Array<{
                          __typename?: 'BffCartResultMessage';
                          type: string;
                          sku?: string | null;
                          message: string;
                      }>;
                  }> | null;
                  delivery?: {
                      __typename?: 'BffCartResultCartItemDelivery';
                      weekRange: string;
                      deliveryStartDate: string;
                      deliveryEndDate: string;
                      calendarStartDate: string;
                      calendarEndDate: string;
                      desiredDeliveryDate: string;
                  } | null;
                  shippingCost?: {
                      __typename?: 'BffCartResultShippingCost';
                      id?: string | null;
                      shippingType: string;
                      price: {
                          __typename?: 'BffCartResultShippingCostPrice';
                          formatted: string;
                          raw?: number | null;
                          currencyCode?: string | null;
                      };
                  } | null;
                  shippingOption?: {
                      __typename?: 'BffCartResultShippingOption';
                      id?: string | null;
                      items: Array<{
                          __typename?: 'BffCartResultShippingOptionItem';
                          key: string;
                          description: string;
                          selected: boolean;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      }>;
                  } | null;
              }>;
          }
        | {
              __typename: 'BffProxyError';
              status: number;
              message: string;
              traceId: string;
              cart?: {
                  __typename?: 'BffCartResult';
                  id: string;
                  count: number;
                  version?: string | null;
                  anayltics?: any | null;
                  canSplitOrder: boolean;
                  isOrderSplit: boolean;
                  showReturnNote: boolean;
                  discountCode?: string | null;
                  isBlocked: boolean;
                  skipPayment: boolean;
                  custom?: any | null;
                  payment?: any | null;
                  totals: Array<{
                      __typename?: 'BffCartResultTotalPrice';
                      formatted: string;
                      rate?: string | null;
                      key: string;
                      raw?: number | null;
                      currencyCode?: string | null;
                  }>;
                  messages: Array<{
                      __typename?: 'BffCartResultMessage';
                      type: string;
                      sku?: string | null;
                      message: string;
                  }>;
                  paymentAmount?: { __typename?: 'BffCartPaymentValue'; currency: string; value: number } | null;
                  shippingAddress?: {
                      __typename?: 'BffCartAddress';
                      email?: string | null;
                      id?: string | null;
                      firstName?: string | null;
                      city?: string | null;
                      country?: string | null;
                      phone?: string | null;
                      additionalAddressInfo?: string | null;
                      lastName?: string | null;
                      region?: string | null;
                      postalCode?: string | null;
                      salutation?: string | null;
                      streetName?: string | null;
                      streetNumber?: string | null;
                      locality?: string | null;
                      defaultShippingAddress?: boolean | null;
                      billing?: boolean | null;
                      shipping?: boolean | null;
                  } | null;
                  billingAddress?: {
                      __typename?: 'BffCartAddress';
                      email?: string | null;
                      id?: string | null;
                      firstName?: string | null;
                      city?: string | null;
                      country?: string | null;
                      phone?: string | null;
                      additionalAddressInfo?: string | null;
                      lastName?: string | null;
                      region?: string | null;
                      postalCode?: string | null;
                      salutation?: string | null;
                      streetName?: string | null;
                      streetNumber?: string | null;
                      locality?: string | null;
                      defaultShippingAddress?: boolean | null;
                      billing?: boolean | null;
                      shipping?: boolean | null;
                  } | null;
                  cartItems: Array<{
                      __typename?: 'BffCartResultCartItem';
                      id: string;
                      lineItems?: Array<{
                          __typename?: 'BffCartResultItem';
                          id: string;
                          sku: string;
                          name: string;
                          type: string;
                          quantity: number;
                          articleNo: string;
                          showQuantity: boolean;
                          isConfigurable: boolean;
                          showReturnNote: boolean;
                          orderLimit: number;
                          delivery?: {
                              __typename?: 'BffCartResultDelivery';
                              weekRange?: string | null;
                              deliveryStartDate?: string | null;
                              deliveryEndDate?: string | null;
                              calendarStartDate?: string | null;
                              calendarEndDate?: string | null;
                              desiredDeliveryDate?: string | null;
                          } | null;
                          image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
                          totalPrice: {
                              __typename?: 'BffProductPrice';
                              formatted?: string | null;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                          messages: Array<{
                              __typename?: 'BffCartResultMessage';
                              type: string;
                              sku?: string | null;
                              message: string;
                          }>;
                      }> | null;
                      delivery?: {
                          __typename?: 'BffCartResultCartItemDelivery';
                          weekRange: string;
                          deliveryStartDate: string;
                          deliveryEndDate: string;
                          calendarStartDate: string;
                          calendarEndDate: string;
                          desiredDeliveryDate: string;
                      } | null;
                      shippingCost?: {
                          __typename?: 'BffCartResultShippingCost';
                          id?: string | null;
                          shippingType: string;
                          price: {
                              __typename?: 'BffCartResultShippingCostPrice';
                              formatted: string;
                              raw?: number | null;
                              currencyCode?: string | null;
                          };
                      } | null;
                      shippingOption?: {
                          __typename?: 'BffCartResultShippingOption';
                          id?: string | null;
                          items: Array<{
                              __typename?: 'BffCartResultShippingOptionItem';
                              key: string;
                              description: string;
                              selected: boolean;
                              price: {
                                  __typename?: 'BffCartResultShippingCostPrice';
                                  formatted: string;
                                  raw?: number | null;
                                  currencyCode?: string | null;
                              };
                          }>;
                      } | null;
                  }>;
              } | null;
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type GetProductsAndConfigurationQueryVariables = Exact<{
    language: BffLanguage;
    market: BffMarket;
    skus: Array<Scalars['String']> | Scalars['String'];
}>;

export type GetProductsAndConfigurationQuery = {
    __typename?: 'Query';
    getProductsAndConfiguration?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | {
              __typename: 'QueryGetProductsAndConfigurationSuccess';
              data: Array<{
                  __typename?: 'BffProduct';
                  name: string;
                  sku: string;
                  showAddToCart: boolean;
                  analytics?: any | null;
                  delivery?: { __typename?: 'BffDelivery'; type: BffDeliveryType; deliveryTime: string } | null;
                  image?: { __typename?: 'BffProductImage'; src: string } | null;
                  price?: { __typename?: 'BffProductPrice'; currencyCode?: string | null; raw?: number | null } | null;
                  configurations?: Array<{
                      __typename?: 'BffProductConfiguration';
                      id: string;
                      type: string;
                      title: string;
                      selected: string;
                      image?: { __typename?: 'BffProductImage'; src: string } | null;
                      values?: Array<{
                          __typename?: 'BffProductConfigurationValue';
                          sku: string;
                          name: string;
                          selected: boolean;
                          image?: { __typename?: 'BffProductImage'; src: string } | null;
                      }> | null;
                  }> | null;
              }>;
          }
        | null;
};

export type VerifyCustomerQueryVariables = Exact<{
    country: BffCountry;
    email: Scalars['String'];
}>;

export type VerifyCustomerQuery = {
    __typename?: 'Query';
    verifyCustomer?:
        | { __typename: 'BffCustomerVerifyModel'; verified: string }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | null;
};

export type GetCustomerQueryVariables = Exact<{
    country: BffCountry;
}>;

export type GetCustomerQuery = {
    __typename?: 'Query';
    getCustomer?:
        | {
              __typename: 'BffCustomerModel';
              customerNumber: string;
              customerType: string;
              email: string;
              firstName: string;
              lastName: string;
              salutation: string;
              title?: string | null;
              companyName?: string | null;
              vatId?: string | null;
              vatIdCountry?: string | null;
              preferredLanguage?: string | null;
              preferredCountry?: string | null;
              addressBook: Array<{
                  __typename?: 'BffCustomerAddress';
                  id: string;
                  city: string;
                  country: string;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  firstName: string;
                  lastName: string;
                  region?: string | null;
                  postalCode: string;
                  salutation: string;
                  streetName: string;
                  streetNumber: string;
                  locality?: string | null;
                  defaultShippingAddress: boolean;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              }>;
          }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | null;
};

export type GetCustomerSummaryQueryVariables = Exact<{
    country: BffCountry;
}>;

export type GetCustomerSummaryQuery = {
    __typename?: 'Query';
    getCustomer?:
        | {
              __typename: 'BffCustomerModel';
              customerNumber: string;
              customerType: string;
              email: string;
              salutation: string;
              title?: string | null;
              firstName: string;
              lastName: string;
              companyName?: string | null;
              vatId?: string | null;
              vatIdCountry?: string | null;
              preferredLanguage?: string | null;
              preferredCountry?: string | null;
              addressBook: Array<{
                  __typename?: 'BffCustomerAddress';
                  id: string;
                  city: string;
                  country: string;
                  phone?: string | null;
                  additionalAddressInfo?: string | null;
                  firstName: string;
                  lastName: string;
                  region?: string | null;
                  postalCode: string;
                  salutation: string;
                  streetName: string;
                  streetNumber: string;
                  locality?: string | null;
                  defaultShippingAddress: boolean;
                  billing?: boolean | null;
                  shipping?: boolean | null;
              }>;
              orders?: Array<{
                  __typename?: 'BffOrderSummary';
                  id: string;
                  orderNumber: string;
                  orderState: string;
                  paymentState: string;
                  numberOfArticles: string;
                  date: string;
                  totalPrice: {
                      __typename?: 'BffProductPrice';
                      raw?: number | null;
                      currencyCode?: string | null;
                      formatted?: string | null;
                  };
              }> | null;
          }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | null;
};

export type GetFromPricesQueryVariables = Exact<{
    censhareProductIds: Array<Scalars['Int']> | Scalars['Int'];
    market: BffMarket;
}>;

export type GetFromPricesQuery = {
    __typename?: 'Query';
    getFromPrices?: Array<{
        __typename?: 'BffProductPriceModel';
        censhareId?: number | null;
        isFromPrice?: boolean | null;
        price?: { __typename?: 'BffProductPrice'; raw?: number | null; currencyCode?: string | null } | null;
    }> | null;
};

export type GetFromPricesBySlugQueryVariables = Exact<{
    slug: Scalars['String'];
    market: BffMarket;
}>;

export type GetFromPricesBySlugQuery = {
    __typename?: 'Query';
    getFromPriceBySlug?:
        | {
              __typename: 'BffProductPriceModel';
              censhareId?: number | null;
              isFromPrice?: boolean | null;
              price?: { __typename?: 'BffProductPrice'; raw?: number | null; currencyCode?: string | null } | null;
          }
        | { __typename: 'BffProxyError'; status: number; message: string; traceId: string }
        | { __typename: 'Error'; message: string }
        | null;
};

export type GetFromPricesBySlugsQueryVariables = Exact<{
    slugs: Array<Scalars['String']> | Scalars['String'];
    market: BffMarket;
}>;

export type GetFromPricesBySlugsQuery = {
    __typename?: 'Query';
    getFromPricesBySlugs?: Array<{
        __typename?: 'BffProductPriceModel';
        isFromPrice?: boolean | null;
        censhareSlug?: string | null;
        price?: { __typename?: 'BffProductPrice'; raw?: number | null; currencyCode?: string | null } | null;
    }> | null;
};

export type BffOrderFragmentFragment = {
    __typename?: 'BffOrder';
    id: string;
    email: string;
    orderNumber: string;
    analytics?: any | null;
    orderStatus: string;
    paymentStatus: string;
    paymentMethod: string;
    paymentBic?: string | null;
    paymentIban?: string | null;
    deliveryNote?: string | null;
    date: string;
    totals: Array<{
        __typename?: 'BffCartResultTotalPrice';
        currencyCode?: string | null;
        formatted: string;
        rate?: string | null;
        raw?: number | null;
        key: string;
    }>;
    billingAddress: {
        __typename?: 'BffCartAddress';
        title?: string | null;
        salutation?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        buildingName?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        city?: string | null;
        region?: string | null;
        country?: string | null;
        phone?: string | null;
        email?: string | null;
        additionalAddressInfo?: string | null;
    };
    shippingAddress: {
        __typename?: 'BffCartAddress';
        title?: string | null;
        salutation?: string | null;
        firstName?: string | null;
        lastName?: string | null;
        streetName?: string | null;
        streetNumber?: string | null;
        buildingName?: string | null;
        locality?: string | null;
        postalCode?: string | null;
        city?: string | null;
        region?: string | null;
        country?: string | null;
        phone?: string | null;
        email?: string | null;
        additionalAddressInfo?: string | null;
    };
    deliveries: Array<{
        __typename?: 'BffOrderDelivery';
        id: string;
        shippingDescription?: string | null;
        shippingOptionDescription?: string | null;
        batchId: string;
        items: Array<{
            __typename?: 'BffOrderDeliveryItems';
            id: string;
            sku: string;
            name?: string | null;
            quantity: number;
            itemStatus?: string | null;
            image?: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number } | null;
            totalPrice: {
                __typename?: 'BffProductPrice';
                currencyCode?: string | null;
                formatted?: string | null;
                raw?: number | null;
            };
        }>;
        delivery: {
            __typename?: 'BffCartResultDelivery';
            weekRange?: string | null;
            deliveryStartDate?: string | null;
            deliveryEndDate?: string | null;
        };
        parcels: Array<{
            __typename?: 'BffOrderDeliveryParcel';
            id?: string | null;
            trackingData?: {
                __typename?: 'BffOrderDeliveryParcelTrackingData';
                trackingId?: string | null;
                carrier?: string | null;
            } | null;
        }>;
    }>;
};

export type GetOrderByCartIdQueryVariables = Exact<{
    cartId: Scalars['String'];
    country: BffCountry;
}>;

export type GetOrderByCartIdQuery = {
    __typename?: 'Query';
    getOrderByCartId?:
        | {
              __typename: 'BffOrder';
              id: string;
              email: string;
              orderNumber: string;
              analytics?: any | null;
              orderStatus: string;
              paymentStatus: string;
              paymentMethod: string;
              paymentBic?: string | null;
              paymentIban?: string | null;
              deliveryNote?: string | null;
              date: string;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  currencyCode?: string | null;
                  formatted: string;
                  rate?: string | null;
                  raw?: number | null;
                  key: string;
              }>;
              billingAddress: {
                  __typename?: 'BffCartAddress';
                  title?: string | null;
                  salutation?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  buildingName?: string | null;
                  locality?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  region?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  email?: string | null;
                  additionalAddressInfo?: string | null;
              };
              shippingAddress: {
                  __typename?: 'BffCartAddress';
                  title?: string | null;
                  salutation?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  buildingName?: string | null;
                  locality?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  region?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  email?: string | null;
                  additionalAddressInfo?: string | null;
              };
              deliveries: Array<{
                  __typename?: 'BffOrderDelivery';
                  id: string;
                  shippingDescription?: string | null;
                  shippingOptionDescription?: string | null;
                  batchId: string;
                  items: Array<{
                      __typename?: 'BffOrderDeliveryItems';
                      id: string;
                      sku: string;
                      name?: string | null;
                      quantity: number;
                      itemStatus?: string | null;
                      image?: {
                          __typename?: 'BffCartResultItemImage';
                          src: string;
                          width: number;
                          height: number;
                      } | null;
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          currencyCode?: string | null;
                          formatted?: string | null;
                          raw?: number | null;
                      };
                  }>;
                  delivery: {
                      __typename?: 'BffCartResultDelivery';
                      weekRange?: string | null;
                      deliveryStartDate?: string | null;
                      deliveryEndDate?: string | null;
                  };
                  parcels: Array<{
                      __typename?: 'BffOrderDeliveryParcel';
                      id?: string | null;
                      trackingData?: {
                          __typename?: 'BffOrderDeliveryParcelTrackingData';
                          trackingId?: string | null;
                          carrier?: string | null;
                      } | null;
                  }>;
              }>;
          }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | null;
};

export type GetOrderQueryVariables = Exact<{
    id: Scalars['String'];
    country: BffCountry;
}>;

export type GetOrderQuery = {
    __typename?: 'Query';
    getOrder?:
        | {
              __typename: 'BffOrder';
              id: string;
              email: string;
              orderNumber: string;
              analytics?: any | null;
              orderStatus: string;
              paymentStatus: string;
              paymentMethod: string;
              paymentBic?: string | null;
              paymentIban?: string | null;
              deliveryNote?: string | null;
              date: string;
              totals: Array<{
                  __typename?: 'BffCartResultTotalPrice';
                  currencyCode?: string | null;
                  formatted: string;
                  rate?: string | null;
                  raw?: number | null;
                  key: string;
              }>;
              billingAddress: {
                  __typename?: 'BffCartAddress';
                  title?: string | null;
                  salutation?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  buildingName?: string | null;
                  locality?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  region?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  email?: string | null;
                  additionalAddressInfo?: string | null;
              };
              shippingAddress: {
                  __typename?: 'BffCartAddress';
                  title?: string | null;
                  salutation?: string | null;
                  firstName?: string | null;
                  lastName?: string | null;
                  streetName?: string | null;
                  streetNumber?: string | null;
                  buildingName?: string | null;
                  locality?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  region?: string | null;
                  country?: string | null;
                  phone?: string | null;
                  email?: string | null;
                  additionalAddressInfo?: string | null;
              };
              deliveries: Array<{
                  __typename?: 'BffOrderDelivery';
                  id: string;
                  shippingDescription?: string | null;
                  shippingOptionDescription?: string | null;
                  batchId: string;
                  items: Array<{
                      __typename?: 'BffOrderDeliveryItems';
                      id: string;
                      sku: string;
                      name?: string | null;
                      quantity: number;
                      itemStatus?: string | null;
                      image?: {
                          __typename?: 'BffCartResultItemImage';
                          src: string;
                          width: number;
                          height: number;
                      } | null;
                      totalPrice: {
                          __typename?: 'BffProductPrice';
                          currencyCode?: string | null;
                          formatted?: string | null;
                          raw?: number | null;
                      };
                  }>;
                  delivery: {
                      __typename?: 'BffCartResultDelivery';
                      weekRange?: string | null;
                      deliveryStartDate?: string | null;
                      deliveryEndDate?: string | null;
                  };
                  parcels: Array<{
                      __typename?: 'BffOrderDeliveryParcel';
                      id?: string | null;
                      trackingData?: {
                          __typename?: 'BffOrderDeliveryParcelTrackingData';
                          trackingId?: string | null;
                          carrier?: string | null;
                      } | null;
                  }>;
              }>;
          }
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'Error'; message: string }
        | null;
};

export type GetProductPricesQueryVariables = Exact<{
    language: BffLanguage;
    market: BffMarket;
    products: Array<BffProductInputProduct> | BffProductInputProduct;
    dataSource?: InputMaybe<BffDataSource>;
}>;

export type GetProductPricesQuery = {
    __typename?: 'Query';
    getProducts: Array<{
        __typename?: 'BffProductModel';
        name?: string | null;
        designer?: string | null;
        url?: string | null;
        image?: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number } | null;
        price?: {
            __typename?: 'BffProductPriceModel';
            grossPrice?: { __typename?: 'BffProductPrice'; currencyCode?: string | null; raw?: number | null } | null;
        } | null;
    }>;
};

export type GetProductsQueryVariables = Exact<{
    language: BffLanguage;
    market: BffMarket;
    products: Array<BffProductInputProduct> | BffProductInputProduct;
}>;

export type GetProductsQuery = {
    __typename?: 'Query';
    getProducts: Array<{
        __typename?: 'BffProductModel';
        sku: string;
        delivery?: {
            __typename?: 'BffDeliveryModel';
            articleNo?: string | null;
            weekRange?: string | null;
            deliveryStartDate?: string | null;
            deliveryEndDate?: string | null;
            shippingCost?: number | null;
            shippingTime?: number | null;
            shippingTimeMax?: number | null;
            shippingTimeMin?: number | null;
            shippingType?: string | null;
        } | null;
        price?: {
            __typename?: 'BffProductPriceModel';
            articleNumber?: string | null;
            configurationId?: string | null;
            price?: {
                __typename?: 'BffProductPrice';
                currencyCode?: string | null;
                currencySymbol?: string | null;
                formatted?: string | null;
                raw?: number | null;
            } | null;
        } | null;
    }>;
};

export type GetProductsDeliveryQueryVariables = Exact<{
    market: BffMarket;
    products: Array<BffProductInputProduct> | BffProductInputProduct;
}>;

export type GetProductsDeliveryQuery = {
    __typename?: 'Query';
    getProducts: Array<{
        __typename?: 'BffProductModel';
        sku: string;
        delivery?: {
            __typename?: 'BffDeliveryModel';
            articleNo?: string | null;
            weekRange?: string | null;
            deliveryStartDate?: string | null;
            deliveryEndDate?: string | null;
            shippingCost?: number | null;
            shippingTime?: number | null;
            shippingTimeMax?: number | null;
            shippingTimeMin?: number | null;
            shippingType?: string | null;
        } | null;
    }>;
};

export type GetShoppingListQueryVariables = Exact<{
    country: Scalars['String'];
    id?: InputMaybe<Scalars['String']>;
}>;

export type GetShoppingListQuery = {
    __typename?: 'Query';
    getShoppingList?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | {
              __typename: 'BffShoppingList';
              id: string;
              isCartShare: boolean;
              lineItems: Array<{
                  __typename?: 'BffShoppingListLineItem';
                  id: string;
                  sku: string;
                  productType: BffShoppingListLineItemProductTypeEnum;
                  name: string;
                  censhareId: string;
                  isSellable: boolean;
                  link?: { __typename?: 'BffLink'; href: string; name?: string | null; title?: string | null } | null;
                  image: { __typename?: 'BffCartResultItemImage'; src: string; width: number; height: number };
              }>;
          }
        | { __typename: 'Error'; message: string }
        | null;
};

export type ShareShoppingListQueryVariables = Exact<{
    country: BffCountry;
    skus?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;

export type ShareShoppingListQuery = {
    __typename?: 'Query';
    shareShoppingList?:
        | { __typename: 'BffProxyError'; traceId: string; status: number; message: string }
        | { __typename: 'BffShoppingListShareLink'; encodedShoppingListId?: string | null }
        | { __typename: 'Error'; message: string }
        | null;
};

export type CheckVatNumberQueryVariables = Exact<{
    vat: Scalars['String'];
    country: BffCountry;
}>;

export type CheckVatNumberQuery = {
    __typename?: 'Query';
    checkVatNumber?:
        | { __typename: 'BffProxyError'; status: number; message: string; traceId: string }
        | { __typename: 'BffVatResponse'; isValid: boolean; name?: string | null; vatNumber: string }
        | { __typename: 'Error'; message: string }
        | null;
};

export const CustomerUpdateAddressFragmentDoc = gql`
    fragment CustomerUpdateAddress on BffCustomerAddressUpdateResult {
        id
        firstName
        city
        country
        phone
        additionalAddressInfo
        firstName
        lastName
        postalCode
        salutation
        streetName
        streetNumber
        locality
        defaultShippingAddress
    }
`;
export const CustomerAddressFragmentDoc = gql`
    fragment CustomerAddress on BffCustomerAddress {
        id
        city
        country
        phone
        additionalAddressInfo
        firstName
        lastName
        region
        postalCode
        salutation
        streetName
        streetNumber
        locality
        defaultShippingAddress
        billing
        shipping
    }
`;
export const CartInfoWithoutPaymentFragmentDoc = gql`
    fragment CartInfoWithoutPayment on BffCartResult {
        id
        version
        count
        anayltics
        canSplitOrder
        isOrderSplit
        showReturnNote
        discountCode
        isBlocked
        skipPayment
        totals {
            formatted
            rate
            key
            raw
            currencyCode
        }
        custom
        messages {
            type
            sku
            message
        }
        paymentAmount {
            currency
            value
        }
        cartItems {
            id
            lineItems {
                id
                sku
                name
                type
                quantity
                articleNo
                showQuantity
                isConfigurable
                showReturnNote
                orderLimit
                image {
                    src
                    width
                    height
                }
                totalPrice {
                    formatted
                    raw
                    currencyCode
                }
                messages {
                    type
                    sku
                    message
                }
            }
        }
    }
`;
export const CartAddressFragmentDoc = gql`
    fragment CartAddress on BffCartAddress {
        id
        firstName
        city
        country
        phone
        additionalAddressInfo
        firstName
        lastName
        region
        postalCode
        salutation
        streetName
        streetNumber
        locality
        defaultShippingAddress
        billing
        shipping
    }
`;
export const CartInfoCheckoutFragmentDoc = gql`
    fragment CartInfoCheckout on BffCartResult {
        id
        count
        version
        anayltics
        canSplitOrder
        isOrderSplit
        showReturnNote
        discountCode
        isBlocked
        skipPayment
        totals {
            formatted
            rate
            key
            raw
            currencyCode
        }
        custom
        payment(country: $country, language: $language)
        messages {
            type
            sku
            message
        }
        paymentAmount {
            currency
            value
        }
        shippingAddress {
            ...CartAddress
            email
        }
        billingAddress {
            ...CartAddress
            email
        }
        cartItems {
            id
            lineItems {
                id
                sku
                delivery {
                    weekRange
                    deliveryStartDate
                    deliveryEndDate
                    calendarStartDate
                    calendarEndDate
                    desiredDeliveryDate
                }
                name
                type
                quantity
                articleNo
                showQuantity
                isConfigurable
                showReturnNote
                orderLimit
                image {
                    src
                    width
                    height
                }
                totalPrice {
                    formatted
                    raw
                    currencyCode
                }
                messages {
                    type
                    sku
                    message
                }
            }
            delivery {
                weekRange
                deliveryStartDate
                deliveryEndDate
                calendarStartDate
                calendarEndDate
                desiredDeliveryDate
            }
            shippingCost {
                id
                shippingType
                price {
                    formatted
                    raw
                    currencyCode
                }
            }
            shippingOption {
                id
                items {
                    key
                    description
                    price {
                        formatted
                        raw
                        currencyCode
                    }
                    selected
                }
            }
        }
    }
    ${CartAddressFragmentDoc}
`;
export const CartInfoCartFragmentDoc = gql`
    fragment CartInfoCart on BffCartResult {
        id
        version
        count
        anayltics
        canSplitOrder
        isOrderSplit
        showReturnNote
        discountCode
        isBlocked
        skipPayment
        totals {
            formatted
            rate
            key
            raw
            currencyCode
        }
        custom
        payment(country: $country, language: $language)
        messages {
            type
            sku
            message
        }
        paymentAmount {
            currency
            value
        }
        cartItems {
            id
            lineItems {
                id
                sku
                name
                type
                quantity
                articleNo
                showQuantity
                isConfigurable
                showReturnNote
                orderLimit
                image {
                    src
                    width
                    height
                }
                totalPrice {
                    formatted
                    raw
                    currencyCode
                }
                messages {
                    type
                    sku
                    message
                }
            }
            shippingOption {
                id
                items {
                    key
                    description
                    price {
                        formatted
                        raw
                        currencyCode
                    }
                    selected
                }
            }
        }
    }
`;
export const BffSubmitOrderResultFragmentFragmentDoc = gql`
    fragment BffSubmitOrderResultFragment on BffSubmitOrderResult {
        cartId
        success
        payment {
            action
            pspReference
            refusalReason
            refusalReasonCode
        }
    }
`;
export const BffOrderFragmentFragmentDoc = gql`
    fragment BffOrderFragment on BffOrder {
        id
        email
        orderNumber
        analytics
        orderStatus
        paymentStatus
        paymentMethod
        paymentBic
        paymentIban
        deliveryNote
        date
        totals {
            currencyCode
            formatted
            rate
            raw
            key
        }
        billingAddress {
            title
            salutation
            firstName
            lastName
            streetName
            streetNumber
            buildingName
            locality
            postalCode
            city
            region
            country
            phone
            email
            additionalAddressInfo
        }
        shippingAddress {
            title
            salutation
            firstName
            lastName
            streetName
            streetNumber
            buildingName
            locality
            postalCode
            city
            region
            country
            phone
            email
            additionalAddressInfo
        }
        deliveries {
            id
            shippingDescription
            shippingOptionDescription
            batchId
            items {
                id
                sku
                name
                image {
                    src
                    width
                    height
                }
                quantity
                itemStatus
                totalPrice {
                    currencyCode
                    formatted
                    raw
                }
            }
            delivery {
                weekRange
                deliveryStartDate
                deliveryEndDate
            }
            parcels {
                id
                trackingData {
                    trackingId
                    carrier
                }
            }
        }
    }
`;
export const AddToCartDocument = gql`
    mutation addToCart($sku: Sku!, $country: BffCountry!, $language: BffLanguage, $quantity: Int) {
        addToCart(country: $country, language: $language, sku: $sku, quantity: $quantity) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                    count
                    messages {
                        type
                        sku
                        message
                    }
                }
            }
            ... on BffCartResult {
                ...CartInfoCart
            }
        }
    }
    ${CartInfoCartFragmentDoc}
`;
export const AddToCartWithoutPaymentDocument = gql`
    mutation addToCartWithoutPayment($sku: Sku!, $country: BffCountry!, $language: BffLanguage, $quantity: Int) {
        addToCart(country: $country, language: $language, sku: $sku, quantity: $quantity) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                    count
                    messages {
                        type
                        sku
                        message
                    }
                }
            }
            ... on BffCartResult {
                ...CartInfoWithoutPayment
            }
        }
    }
    ${CartInfoWithoutPaymentFragmentDoc}
`;
export const ChangeCartDocument = gql`
    mutation changeCart($lineItemId: String!, $quantity: Int!, $country: BffCountry!, $language: BffLanguage) {
        changeCart(lineItemId: $lineItemId, country: $country, quantity: $quantity) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    ...CartInfoCart
                }
            }
            ... on BffCartResult {
                ...CartInfoCart
            }
        }
    }
    ${CartInfoCartFragmentDoc}
`;
export const SplitOrderDocument = gql`
    mutation splitOrder($country: BffCountry!, $language: BffLanguage) {
        splitOrder(country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    ...CartInfoCheckout
                }
            }
            ... on BffCartResult {
                ...CartInfoCheckout
            }
        }
    }
    ${CartInfoCheckoutFragmentDoc}
`;
export const ChangeCartAddressesDocument = gql`
    mutation changeCartAddresses(
        $shippingAddress: BffCartAddressUpdate!
        $billingAddress: BffCartAddressUpdate!
        $country: BffCountry!
        $language: BffLanguage
    ) {
        changeCartAddresses(country: $country, billingAddress: $billingAddress, shippingAddress: $shippingAddress) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                }
            }
            ... on BffCartResult {
                ...CartInfoCheckout
            }
        }
    }
    ${CartInfoCheckoutFragmentDoc}
`;
export const UpdateShippingOptionDocument = gql`
    mutation updateShippingOption(
        $shippingOptionId: String!
        $shippingOptionKey: String!
        $country: BffCountry!
        $language: BffLanguage
    ) {
        updateShippingOption(
            shippingOptionId: $shippingOptionId
            shippingOptionKey: $shippingOptionKey
            country: $country
        ) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                }
            }
            ... on BffCartResult {
                ...CartInfoCheckout
            }
        }
    }
    ${CartInfoCheckoutFragmentDoc}
`;
export const UpdateDesiredDeliveryDateDocument = gql`
    mutation updateDesiredDeliveryDate($shippingBatchId: String!, $date: String!, $country: BffCountry!) {
        updateDesiredDeliveryDate(shippingBatchId: $shippingBatchId, date: $date, country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                }
            }
            ... on BffUpdateDesiredDeilveryDateResult {
                shippingBatchId
                desiredDateSelected
                userSelectedDeliveryStartDate
                userSelectedDeliveryEndDate
                deliveryTime
                calendarStartDate
                calendarEndDate
            }
        }
    }
`;
export const InitiatePaymentDocument = gql`
    mutation initiatePayment($country: BffCountry!, $paymentMethod: JSON!, $riskData: JSON) {
        initiatePayment(country: $country, paymentMethod: $paymentMethod, riskData: $riskData) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                }
            }
            ... on MutationInitiatePaymentSuccess {
                data
            }
        }
    }
`;
export const SubmitExpressOrderDocument = gql`
    mutation submitExpressOrder(
        $country: BffCountry!
        $language: BffLanguage!
        $deliveryAddress: BffCustomerAddressUpdate!
        $email: String!
        $paymentMethod: String!
        $paymentDetails: JSON!
    ) {
        submitExpressOrder(
            country: $country
            language: $language
            deliveryAddress: $deliveryAddress
            email: $email
            paymentMethod: $paymentMethod
            paymentDetails: $paymentDetails
        ) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                }
            }
            ... on BffSubmitOrderResult {
                ...BffSubmitOrderResultFragment
            }
        }
    }
    ${BffSubmitOrderResultFragmentFragmentDoc}
`;
export const SubmitOrderDocument = gql`
    mutation submitOrder(
        $country: BffCountry!
        $language: BffLanguage!
        $paymentMethod: String!
        $paymentDetails: JSON!
        $deliveryNote: String
        $customerProfession: BffProfession
    ) {
        submitOrder(
            country: $country
            language: $language
            paymentMethod: $paymentMethod
            paymentDetails: $paymentDetails
            deliveryNote: $deliveryNote
            customerProfession: $customerProfession
        ) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                }
            }
            ... on BffSubmitOrderResult {
                ...BffSubmitOrderResultFragment
            }
        }
    }
    ${BffSubmitOrderResultFragmentFragmentDoc}
`;
export const SubmitMaterialsOrderDocument = gql`
    mutation submitMaterialsOrder(
        $country: BffCountry!
        $language: BffLanguage!
        $deliveryNote: String
        $customerProfession: BffProfession!
    ) {
        submitMaterialsOrder(
            country: $country
            language: $language
            deliveryNote: $deliveryNote
            customerProfession: $customerProfession
        ) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                }
            }
            ... on BffSubmitOrderResult {
                ...BffSubmitOrderResultFragment
            }
        }
    }
    ${BffSubmitOrderResultFragmentFragmentDoc}
`;
export const SubmitPaymentActionDocument = gql`
    mutation submitPaymentAction($country: BffCountry!, $paymentMethod: String!, $data: JSON!) {
        submitPaymentAction(country: $country, paymentMethod: $paymentMethod, data: $data) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    id
                }
            }
            ... on BffSubmitOrderResult {
                ...BffSubmitOrderResultFragment
            }
        }
    }
    ${BffSubmitOrderResultFragmentFragmentDoc}
`;
export const AddDiscountCodeToCartDocument = gql`
    mutation addDiscountCodeToCart($code: String!, $country: BffCountry!, $language: BffLanguage) {
        addDiscountCodeToCart(code: $code, country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    ...CartInfoCart
                }
            }
            ... on BffCartResult {
                ...CartInfoCart
            }
        }
    }
    ${CartInfoCartFragmentDoc}
`;
export const RemoveDiscountCodeFromCartDocument = gql`
    mutation removeDiscountCodeFromCart($code: String!, $country: BffCountry!, $language: BffLanguage) {
        removeDiscountCodeFromCart(code: $code, country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    ...CartInfoCart
                }
            }
            ... on BffCartResult {
                ...CartInfoCart
            }
        }
    }
    ${CartInfoCartFragmentDoc}
`;
export const CreateCartShareDocument = gql`
    mutation createCartShare($country: BffCountry!) {
        createCartShare(country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
            }
            ... on BffCartShareResult {
                url
            }
        }
    }
`;
export const CreateCustomerDocument = gql`
    mutation createCustomer($country: BffCountry!, $turnstile: String!, $customerData: BffCustomerCreate!) {
        createCustomer(country: $country, turnstile: $turnstile, customerData: $customerData) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffCustomerModel {
                email
                firstName
                lastName
                salutation
                title
                companyName
                customerType
                vatId
                vatIdCountry
                preferredLanguage
                preferredCountry
            }
        }
    }
`;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($country: BffCountry!, $customerData: BffCustomerUpdate!) {
        updateCustomer(country: $country, customerData: $customerData) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffCustomerModel {
                customerNumber
                customerType
                email
                firstName
                lastName
                salutation
                title
                companyName
                vatId
                vatIdCountry
                preferredLanguage
                preferredCountry
                addressBook(country: $country) {
                    ...CustomerAddress
                }
            }
        }
    }
    ${CustomerAddressFragmentDoc}
`;
export const UpdateCustomerAddressDocument = gql`
    mutation updateCustomerAddress($id: String!, $country: BffCountry!, $address: BffCustomerAddressUpdate!) {
        updateCustomerAddress(id: $id, country: $country, address: $address) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffCustomerAddressUpdateResult {
                ...CustomerUpdateAddress
            }
        }
    }
    ${CustomerUpdateAddressFragmentDoc}
`;
export const CreateCustomerAddressDocument = gql`
    mutation createCustomerAddress($country: BffCountry!, $address: BffCustomerAddressUpdate!) {
        createCustomerAddress(country: $country, address: $address) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffCustomerAddressUpdateResult {
                ...CustomerUpdateAddress
            }
        }
    }
    ${CustomerUpdateAddressFragmentDoc}
`;
export const DeleteCustomerAddressDocument = gql`
    mutation deleteCustomerAddress($country: BffCountry!, $id: String!) {
        deleteCustomerAddress(country: $country, id: $id) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffCustomerAddressDeleteResult {
                id
            }
        }
    }
`;
export const CreateCustomerShoppingListDocument = gql`
    mutation createCustomerShoppingList($country: String!, $lineItems: [BffShoppingListLineItemInput!]!) {
        createCustomerShoppingList(country: $country, lineItems: $lineItems) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffShoppingList {
                id
                isCartShare
                lineItems {
                    id
                    sku
                    productType
                    name
                    link {
                        href
                        name
                        title
                    }
                    censhareId
                    isSellable
                    image {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
export const DeleteCustomerShoppingListItemDocument = gql`
    mutation deleteCustomerShoppingListItem($country: String!, $id: String!) {
        deleteCustomerShoppingListItem(country: $country, id: $id) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffShoppingList {
                id
                isCartShare
                lineItems {
                    id
                    sku
                    productType
                    name
                    link {
                        href
                        name
                        title
                    }
                    censhareId
                    isSellable
                    image {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
export const GetCartDocument = gql`
    query getCart($country: BffCountry!, $language: BffLanguage, $refresh: Boolean = false) {
        getCart(country: $country, refresh: $refresh) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    ...CartInfoCart
                }
            }
            ... on BffCartResult {
                ...CartInfoCart
            }
        }
    }
    ${CartInfoCartFragmentDoc}
`;
export const GetCartCheckoutDocument = gql`
    query getCartCheckout($country: BffCountry!, $language: BffLanguage, $refresh: Boolean = false) {
        getCart(country: $country, refresh: $refresh) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
                cart {
                    ...CartInfoCheckout
                }
            }
            ... on BffCartResult {
                ...CartInfoCheckout
            }
        }
    }
    ${CartInfoCheckoutFragmentDoc}
`;
export const GetProductsAndConfigurationDocument = gql`
    query getProductsAndConfiguration($language: BffLanguage!, $market: BffMarket!, $skus: [String!]!) {
        getProductsAndConfiguration(language: $language, market: $market, skus: $skus) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on QueryGetProductsAndConfigurationSuccess {
                data {
                    name
                    sku
                    showAddToCart
                    delivery {
                        type
                        deliveryTime
                    }
                    image {
                        src
                    }
                    price {
                        currencyCode
                        raw
                    }
                    analytics
                    configurations {
                        id
                        type
                        title
                        selected
                        image {
                            src
                        }
                        values {
                            sku
                            name
                            selected
                            image {
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const VerifyCustomerDocument = gql`
    query verifyCustomer($country: BffCountry!, $email: String!) {
        verifyCustomer(country: $country, email: $email) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffCustomerVerifyModel {
                verified
            }
        }
    }
`;
export const GetCustomerDocument = gql`
    query getCustomer($country: BffCountry!) {
        getCustomer(country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffCustomerModel {
                customerNumber
                customerType
                email
                firstName
                lastName
                salutation
                title
                companyName
                vatId
                vatIdCountry
                preferredLanguage
                preferredCountry
                addressBook(country: $country) {
                    ...CustomerAddress
                }
            }
        }
    }
    ${CustomerAddressFragmentDoc}
`;
export const GetCustomerSummaryDocument = gql`
    query getCustomerSummary($country: BffCountry!) {
        getCustomer(country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffCustomerModel {
                customerNumber
                customerType
                email
                salutation
                title
                firstName
                lastName
                companyName
                vatId
                vatIdCountry
                preferredLanguage
                preferredCountry
                addressBook(country: $country) {
                    ...CustomerAddress
                }
                orders(country: $country) {
                    id
                    orderNumber
                    orderState
                    paymentState
                    numberOfArticles
                    date
                    totalPrice {
                        raw
                        currencyCode
                        formatted
                    }
                }
            }
        }
    }
    ${CustomerAddressFragmentDoc}
`;
export const GetFromPricesDocument = gql`
    query getFromPrices($censhareProductIds: [Int!]!, $market: BffMarket!) {
        getFromPrices(censhareProductIds: $censhareProductIds, market: $market) {
            censhareId
            isFromPrice
            price {
                raw
                currencyCode
            }
        }
    }
`;
export const GetFromPricesBySlugDocument = gql`
    query getFromPricesBySlug($slug: String!, $market: BffMarket!) {
        getFromPriceBySlug(slug: $slug, market: $market) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
            }
            ... on BffProductPriceModel {
                censhareId
                isFromPrice
                price {
                    raw
                    currencyCode
                }
            }
        }
    }
`;
export const GetFromPricesBySlugsDocument = gql`
    query getFromPricesBySlugs($slugs: [String!]!, $market: BffMarket!) {
        getFromPricesBySlugs(slugs: $slugs, market: $market) {
            isFromPrice
            censhareSlug
            price {
                raw
                currencyCode
            }
        }
    }
`;
export const GetOrderByCartIdDocument = gql`
    query getOrderByCartId($cartId: String!, $country: BffCountry!) {
        getOrderByCartId(cartId: $cartId, country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffOrder {
                ...BffOrderFragment
            }
        }
    }
    ${BffOrderFragmentFragmentDoc}
`;
export const GetOrderDocument = gql`
    query getOrder($id: String!, $country: BffCountry!) {
        getOrder(id: $id, country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffOrder {
                ...BffOrderFragment
            }
        }
    }
    ${BffOrderFragmentFragmentDoc}
`;
export const GetProductPricesDocument = gql`
    query getProductPrices(
        $language: BffLanguage!
        $market: BffMarket!
        $products: [BffProductInputProduct!]!
        $dataSource: BffDataSource
    ) {
        getProducts(market: $market, products: $products, dataSource: $dataSource) {
            name
            designer
            url
            image {
                src
                width
                height
            }
            price(language: $language) {
                grossPrice {
                    currencyCode
                    raw
                }
            }
        }
    }
`;
export const GetProductsDocument = gql`
    query getProducts($language: BffLanguage!, $market: BffMarket!, $products: [BffProductInputProduct!]!) {
        getProducts(market: $market, products: $products) {
            sku
            delivery {
                articleNo
                weekRange
                deliveryStartDate
                deliveryEndDate
                shippingCost
                shippingTime
                shippingTimeMax
                shippingTimeMin
                shippingType
            }
            price(language: $language) {
                articleNumber
                configurationId
                price {
                    currencyCode
                    currencySymbol
                    formatted
                    raw
                }
            }
        }
    }
`;
export const GetProductsDeliveryDocument = gql`
    query getProductsDelivery($market: BffMarket!, $products: [BffProductInputProduct!]!) {
        getProducts(market: $market, products: $products) {
            sku
            delivery {
                articleNo
                weekRange
                deliveryStartDate
                deliveryEndDate
                shippingCost
                shippingTime
                shippingTimeMax
                shippingTimeMin
                shippingType
            }
        }
    }
`;
export const GetShoppingListDocument = gql`
    query getShoppingList($country: String!, $id: String) {
        getShoppingList(country: $country, id: $id) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffShoppingList {
                id
                isCartShare
                lineItems {
                    id
                    sku
                    productType
                    name
                    link {
                        href
                        name
                        title
                    }
                    censhareId
                    isSellable
                    image {
                        src
                        width
                        height
                    }
                }
            }
        }
    }
`;
export const ShareShoppingListDocument = gql`
    query shareShoppingList($country: BffCountry!, $skus: [String!]) {
        shareShoppingList(country: $country, skus: $skus) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                traceId
                status
                message
            }
            ... on BffShoppingListShareLink {
                encodedShoppingListId
            }
        }
    }
`;
export const CheckVatNumberDocument = gql`
    query checkVatNumber($vat: String!, $country: BffCountry!) {
        checkVatNumber(vat: $vat, country: $country) {
            __typename
            ... on Error {
                message
            }
            ... on BffProxyError {
                status
                message
                traceId
            }
            ... on BffVatResponse {
                isValid
                name
                vatNumber
            }
        }
    }
`;

export type SdkFunctionWrapper = <T>(
    action: (requestHeaders?: Record<string, string>) => Promise<T>,
    operationName: string,
    operationType?: string
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
    return {
        addToCart(
            variables: AddToCartMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<AddToCartMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<AddToCartMutation>(AddToCartDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'addToCart',
                'mutation'
            );
        },
        addToCartWithoutPayment(
            variables: AddToCartWithoutPaymentMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<AddToCartWithoutPaymentMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<AddToCartWithoutPaymentMutation>(AddToCartWithoutPaymentDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'addToCartWithoutPayment',
                'mutation'
            );
        },
        changeCart(
            variables: ChangeCartMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ChangeCartMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ChangeCartMutation>(ChangeCartDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'changeCart',
                'mutation'
            );
        },
        splitOrder(
            variables: SplitOrderMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<SplitOrderMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SplitOrderMutation>(SplitOrderDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'splitOrder',
                'mutation'
            );
        },
        changeCartAddresses(
            variables: ChangeCartAddressesMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ChangeCartAddressesMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ChangeCartAddressesMutation>(ChangeCartAddressesDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'changeCartAddresses',
                'mutation'
            );
        },
        updateShippingOption(
            variables: UpdateShippingOptionMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateShippingOptionMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateShippingOptionMutation>(UpdateShippingOptionDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'updateShippingOption',
                'mutation'
            );
        },
        updateDesiredDeliveryDate(
            variables: UpdateDesiredDeliveryDateMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateDesiredDeliveryDateMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateDesiredDeliveryDateMutation>(UpdateDesiredDeliveryDateDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'updateDesiredDeliveryDate',
                'mutation'
            );
        },
        initiatePayment(
            variables: InitiatePaymentMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<InitiatePaymentMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<InitiatePaymentMutation>(InitiatePaymentDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'initiatePayment',
                'mutation'
            );
        },
        submitExpressOrder(
            variables: SubmitExpressOrderMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<SubmitExpressOrderMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SubmitExpressOrderMutation>(SubmitExpressOrderDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'submitExpressOrder',
                'mutation'
            );
        },
        submitOrder(
            variables: SubmitOrderMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<SubmitOrderMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SubmitOrderMutation>(SubmitOrderDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'submitOrder',
                'mutation'
            );
        },
        submitMaterialsOrder(
            variables: SubmitMaterialsOrderMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<SubmitMaterialsOrderMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SubmitMaterialsOrderMutation>(SubmitMaterialsOrderDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'submitMaterialsOrder',
                'mutation'
            );
        },
        submitPaymentAction(
            variables: SubmitPaymentActionMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<SubmitPaymentActionMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<SubmitPaymentActionMutation>(SubmitPaymentActionDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'submitPaymentAction',
                'mutation'
            );
        },
        addDiscountCodeToCart(
            variables: AddDiscountCodeToCartMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<AddDiscountCodeToCartMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<AddDiscountCodeToCartMutation>(AddDiscountCodeToCartDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'addDiscountCodeToCart',
                'mutation'
            );
        },
        removeDiscountCodeFromCart(
            variables: RemoveDiscountCodeFromCartMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<RemoveDiscountCodeFromCartMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<RemoveDiscountCodeFromCartMutation>(RemoveDiscountCodeFromCartDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'removeDiscountCodeFromCart',
                'mutation'
            );
        },
        createCartShare(
            variables: CreateCartShareMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateCartShareMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateCartShareMutation>(CreateCartShareDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'createCartShare',
                'mutation'
            );
        },
        createCustomer(
            variables: CreateCustomerMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateCustomerMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateCustomerMutation>(CreateCustomerDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'createCustomer',
                'mutation'
            );
        },
        updateCustomer(
            variables: UpdateCustomerMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateCustomerMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateCustomerMutation>(UpdateCustomerDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'updateCustomer',
                'mutation'
            );
        },
        updateCustomerAddress(
            variables: UpdateCustomerAddressMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<UpdateCustomerAddressMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<UpdateCustomerAddressMutation>(UpdateCustomerAddressDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'updateCustomerAddress',
                'mutation'
            );
        },
        createCustomerAddress(
            variables: CreateCustomerAddressMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateCustomerAddressMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateCustomerAddressMutation>(CreateCustomerAddressDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'createCustomerAddress',
                'mutation'
            );
        },
        deleteCustomerAddress(
            variables: DeleteCustomerAddressMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<DeleteCustomerAddressMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<DeleteCustomerAddressMutation>(DeleteCustomerAddressDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'deleteCustomerAddress',
                'mutation'
            );
        },
        createCustomerShoppingList(
            variables: CreateCustomerShoppingListMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CreateCustomerShoppingListMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CreateCustomerShoppingListMutation>(CreateCustomerShoppingListDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'createCustomerShoppingList',
                'mutation'
            );
        },
        deleteCustomerShoppingListItem(
            variables: DeleteCustomerShoppingListItemMutationVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<DeleteCustomerShoppingListItemMutation> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<DeleteCustomerShoppingListItemMutation>(
                        DeleteCustomerShoppingListItemDocument,
                        variables,
                        { ...requestHeaders, ...wrappedRequestHeaders }
                    ),
                'deleteCustomerShoppingListItem',
                'mutation'
            );
        },
        getCart(variables: GetCartQueryVariables, requestHeaders?: Dom.RequestInit['headers']): Promise<GetCartQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetCartQuery>(GetCartDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getCart',
                'query'
            );
        },
        getCartCheckout(
            variables: GetCartCheckoutQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetCartCheckoutQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetCartCheckoutQuery>(GetCartCheckoutDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getCartCheckout',
                'query'
            );
        },
        getProductsAndConfiguration(
            variables: GetProductsAndConfigurationQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetProductsAndConfigurationQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProductsAndConfigurationQuery>(GetProductsAndConfigurationDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getProductsAndConfiguration',
                'query'
            );
        },
        verifyCustomer(
            variables: VerifyCustomerQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<VerifyCustomerQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<VerifyCustomerQuery>(VerifyCustomerDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'verifyCustomer',
                'query'
            );
        },
        getCustomer(
            variables: GetCustomerQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetCustomerQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetCustomerQuery>(GetCustomerDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getCustomer',
                'query'
            );
        },
        getCustomerSummary(
            variables: GetCustomerSummaryQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetCustomerSummaryQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetCustomerSummaryQuery>(GetCustomerSummaryDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getCustomerSummary',
                'query'
            );
        },
        getFromPrices(
            variables: GetFromPricesQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetFromPricesQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetFromPricesQuery>(GetFromPricesDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getFromPrices',
                'query'
            );
        },
        getFromPricesBySlug(
            variables: GetFromPricesBySlugQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetFromPricesBySlugQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetFromPricesBySlugQuery>(GetFromPricesBySlugDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getFromPricesBySlug',
                'query'
            );
        },
        getFromPricesBySlugs(
            variables: GetFromPricesBySlugsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetFromPricesBySlugsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetFromPricesBySlugsQuery>(GetFromPricesBySlugsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getFromPricesBySlugs',
                'query'
            );
        },
        getOrderByCartId(
            variables: GetOrderByCartIdQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetOrderByCartIdQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetOrderByCartIdQuery>(GetOrderByCartIdDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getOrderByCartId',
                'query'
            );
        },
        getOrder(
            variables: GetOrderQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetOrderQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetOrderQuery>(GetOrderDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getOrder',
                'query'
            );
        },
        getProductPrices(
            variables: GetProductPricesQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetProductPricesQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProductPricesQuery>(GetProductPricesDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getProductPrices',
                'query'
            );
        },
        getProducts(
            variables: GetProductsQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetProductsQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProductsQuery>(GetProductsDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getProducts',
                'query'
            );
        },
        getProductsDelivery(
            variables: GetProductsDeliveryQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetProductsDeliveryQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetProductsDeliveryQuery>(GetProductsDeliveryDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getProductsDelivery',
                'query'
            );
        },
        getShoppingList(
            variables: GetShoppingListQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<GetShoppingListQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<GetShoppingListQuery>(GetShoppingListDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'getShoppingList',
                'query'
            );
        },
        shareShoppingList(
            variables: ShareShoppingListQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<ShareShoppingListQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<ShareShoppingListQuery>(ShareShoppingListDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'shareShoppingList',
                'query'
            );
        },
        checkVatNumber(
            variables: CheckVatNumberQueryVariables,
            requestHeaders?: Dom.RequestInit['headers']
        ): Promise<CheckVatNumberQuery> {
            return withWrapper(
                (wrappedRequestHeaders) =>
                    client.request<CheckVatNumberQuery>(CheckVatNumberDocument, variables, {
                        ...requestHeaders,
                        ...wrappedRequestHeaders
                    }),
                'checkVatNumber',
                'query'
            );
        }
    };
}
export type Sdk = ReturnType<typeof getSdk>;
