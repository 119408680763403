/*
eslint @typescript-eslint/no-unused-vars: ["error", { "argsIgnorePattern": "^_" }]
*/

import { client, getBffLanguageAndCountry } from 'common/graphql/client';

import {
    BffCountry,
    BffShoppingList,
    BffShoppingListLineItem,
    BffShoppingListLineItemInput,
    BffShoppingListShareLink
} from 'common/graphql/sdk';
import Translate, { useTranslations } from 'common/primitives/translations';
import { generateProductImageUrl } from 'common/styles';
import { get, post } from 'common/utils/fetch';
import { CheckoutCountries } from 'components/checkout-v2/config';
import { canUseDOM } from 'exenv';
import lget from 'lodash/get';
import mitt from 'mitt';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useConfig } from './useBoostrap';
import { FeatureFlags, useFeature } from './useFeature';
import { useConfirmModal } from './useModal';

/**
 * BFF INTERFACE
 */
class WishlistBffStorage {
    public static instance = new WishlistBffStorage();
    private wishlist: BffShoppingList | undefined;
    private isFetching = false;
    private emitter = mitt();
    /**
     * Utiliity function to use legacy skus
     * @param sku
     */
    public sanitizeSku = (sku: string): string => {
        return sanitizeSku(sku);
    };

    public sanitizeLinitems = (lineItems: BffShoppingListLineItemInput[]): BffShoppingListLineItemInput[] => {
        return lineItems.map((i: BffShoppingListLineItemInput) => ({ ...i, sku: this.sanitizeSku(i.sku) }));
    };

    /**
     * Loads the shoppinglist
     * @returns
     */
    public getShoppingList = async (fetchRemote?: boolean): Promise<BffShoppingList | undefined> => {
        if (!canUseDOM) {
            return undefined;
        }
        const { country } = getBffLanguageAndCountry();

        if (this.isFetching) {
            return new Promise((resolve) => {
                this.emitter.on('wishlist', () => {
                    resolve(this.wishlist);
                });
            });
        }

        // Only return the cached wishllist if
        // - it istnt forced via the fetchRemote flag
        if (this.wishlist && !fetchRemote) {
            return this.wishlist;
        }

        this.isFetching = true;
        const { getShoppingList: response } = await client.getShoppingList({
            country
        });
        this.isFetching = false;

        if (response?.__typename === 'BffShoppingList') {
            this.wishlist = response;

            // the emitter is used to inform other components about the wishlist changes
            // e.g the wishlist count in the navigation.
            this.emitter.emit('wishlist');
        }

        return this.wishlist;
    };

    public getSharedShoppingList = async (shareId: string): Promise<BffShoppingList | undefined> => {
        if (!canUseDOM) {
            return undefined;
        }
        const { country } = getBffLanguageAndCountry();

        const { getShoppingList: response } = await client.getShoppingList({
            country,
            id: shareId
        });
        if (response?.__typename === 'BffShoppingList') {
            return response;
        }
    };

    /**
     * Adds items to a shopping list if not present
     * @param lineItems
     * @returns
     */
    public createShoppingList = async (
        lineItems: BffShoppingListLineItemInput[]
    ): Promise<BffShoppingList | undefined> => {
        if (!canUseDOM) {
            return undefined;
        }
        const countryCode = useConfig<CheckoutCountries>('country', CheckoutCountries.DE);

        const { createCustomerShoppingList: response } = await client.createCustomerShoppingList({
            country: (countryCode.toUpperCase() as unknown) as BffCountry,
            lineItems: this.sanitizeLinitems(lineItems)
        });

        return response?.__typename === 'BffShoppingList' ? response : undefined;
    };

    /**
     * Deletes a line item
     * @param lineItems
     * @returns
     */
    public deleteShoppingListItem = async (id: string): Promise<BffShoppingList | undefined> => {
        if (!canUseDOM) {
            return undefined;
        }
        const countryCode = useConfig<CheckoutCountries>('country', CheckoutCountries.DE);

        const { deleteCustomerShoppingListItem: response } = await client.deleteCustomerShoppingListItem({
            country: (countryCode.toUpperCase() as unknown) as BffCountry,
            id: this.sanitizeSku(id)
        });

        return response?.__typename === 'BffShoppingList' ? response : undefined;
    };

    /**
     * Share the shoppinglist
     * @returns
     */
    public shareShoppingList = async (
        skus?: ({ sku: string; productType: LegacyWishlistProductType } | string)[]
    ): Promise<BffShoppingListShareLink | undefined> => {
        if (!canUseDOM) {
            return undefined;
        }
        // Convert legacy to new format
        const skuStringList = skus?.map((s) => (typeof s === 'string' ? this.sanitizeSku(s) : this.sanitizeSku(s.sku)));
        const countryCode = useConfig<CheckoutCountries>('country', CheckoutCountries.DE);
        const code = useConfig<string>('code', '');

        const { shareShoppingList: response } = await client.shareShoppingList({
            country: (countryCode.toUpperCase() as unknown) as BffCountry,
            skus: skuStringList
        });

        if (response?.__typename === 'BffShoppingListShareLink') {
            return {
                url: `${window.location.protocol}//${window.location.host}/${code}/wishlist/${response.encodedShoppingListId}`
            };
        } else {
            return undefined;
        }
    };
}

/**
 * LEGACY INTERFACE
 */

export type LegacyWishlistProductType = 'product' | 'material';
export interface LegacyWishlistItem extends Omit<Pick<BffShoppingListLineItem, 'sku' | 'id'>, 'productType'> {
    productType: LegacyWishlistProductType;
}
export type LegacyWishlist = {
    lineItems: LegacyWishlistItem[];
};
interface LegacyWishlistItemInput extends BffShoppingListLineItemInput {
    productType: LegacyWishlistProductType;
}
export type LegacyWishlistShareResponse = {
    products: string[];
    materials: string[];
    shortId: string;
    shareURL: string;
};

class WishlistLegacyStorage {
    WISHLIST_KEY = 'wishlist';
    wishlistUrl;
    shareWishlistUrl;
    productMaterialsUrl;

    constructor() {
        // Assure this is a singleton and the class is not initiated again throughout renders
        if ((WishlistLegacyStorage as any)._instance) {
            return (WishlistLegacyStorage as any)._instance;
        }
        (WishlistLegacyStorage as any)._instance = this;

        this.productMaterialsUrl = useConfig(
            'productMaterialsUrl',
            'https://www.vitra.com/en-de/api/json/wishlistproductmaterial'
        );
        this.wishlistUrl = useConfig('wishlistUrl', '/wishlist');
        this.shareWishlistUrl = useConfig('shareWishlistUrl', '/api/v1/share-wishlist');
    }

    public sanitizeSku = (sku: string): string => {
        return sku;
    };

    public writeToStorage = async (wl: LegacyWishlist) => {
        const wishlistStorage = wl.lineItems.map((li) => ({
            id: li.id,
            type: li.productType
        }));

        localStorage.setItem(this.WISHLIST_KEY, JSON.stringify(wishlistStorage));
    };

    private fetchImagesAndLinks = async (w: LegacyWishlist): Promise<LegacyWishlist> => {
        if (w.lineItems.length === 0) {
            return w;
        }
        try {
            const cleanedLineItems = w.lineItems.map((li) => ({
                id: sanitizeSku(li.id),
                sku: sanitizeSku(li.sku),
                productType: li.productType
            }));

            // Add Conf| and material|
            const productSkus = cleanedLineItems.filter((l) => l.productType === 'product').map((l) => `Conf|${l.sku}`);
            const materialSkus = cleanedLineItems.filter((l) => l.productType === 'material').map((l) => `${l.sku}`);

            const resp: any = await get(
                `${this.productMaterialsUrl}?products=${encodeURIComponent(
                    productSkus.join(',')
                )}&materials=${encodeURIComponent(materialSkus.join(','))}`
            );

            const m = resp.data.products.map((p: any) => ({
                id: p.id,
                sku: sanitizeSku(p.id),
                productType: p.material ? 'material' : 'product',
                name: p.name,
                link: p.link,
                cart: lget(p, 'cart', false),
                image: {
                    src: generateProductImageUrl(p.id)
                }
            }));

            return { ...w, lineItems: m };
        } catch (err) {
            console.log(err);
        }
        return w;
    };

    private getFromStorage = (): LegacyWishlist | undefined => {
        if (!canUseDOM) {
            return;
        }
        try {
            const wl = localStorage.getItem(this.WISHLIST_KEY);
            if (wl) {
                const items = JSON.parse(wl);
                const lineItems = items.map((i: any) => ({ id: i.id, sku: i.id, productType: i.type }));
                return { lineItems };
            }
        } catch (err) {
            console.log(err);
        }
    };

    /**
     * Loads the shoppinglist
     * @returns
     */
    public getShoppingList = async (fetchRemote?: boolean): Promise<LegacyWishlist | undefined> => {
        if (!canUseDOM) {
            return;
        }
        let lineItems: LegacyWishlistItem[] = [];

        lineItems = this.getFromStorage()?.lineItems || [];

        if (fetchRemote) {
            return await this.fetchImagesAndLinks({ lineItems });
        }

        return { lineItems };
    };

    // This is just to make the interface compatible with the BFF
    public getSharedShoppingList = async (): Promise<LegacyWishlist | undefined> => {
        if (!canUseDOM) {
            return;
        }
        const lineItems: LegacyWishlistItem[] = [];
        return { lineItems };
    };

    /**
     * Adds items to a shopping list if not present
     * @param lineItems
     * @returns
     */
    public createShoppingList = async (lineItems: [LegacyWishlistItemInput]): Promise<LegacyWishlist | undefined> => {
        const wl = (await this.getShoppingList()) || { lineItems: [] };

        for (const index in lineItems) {
            const foundIndex = wl.lineItems.findIndex((i) => i.sku === lineItems[index].sku);
            if (foundIndex === -1) {
                wl.lineItems.push({
                    id: lineItems[index].sku,
                    sku: lineItems[index].sku,
                    productType: lineItems[index].productType
                });
            }
        }
        this.writeToStorage(wl);

        return await this.fetchImagesAndLinks(wl);
    };

    /**
     * Deletes a line item
     * @param lineItems
     * @returns
     */
    public deleteShoppingListItem = async (id: string): Promise<LegacyWishlist | undefined> => {
        const wl = (await this.getShoppingList()) || { lineItems: [] };

        const foundIndex = wl.lineItems.findIndex((i) => i.id === id);
        if (foundIndex !== -1) {
            wl.lineItems.splice(foundIndex, 1);
        }
        this.writeToStorage(wl);
        return wl;
    };

    /**
     * Share the shoppinglist
     * @returns
     */
    public shareShoppingList = async (
        skus?: {
            sku: any;
            productType: LegacyWishlistProductType;
        }[]
    ): Promise<BffShoppingListShareLink | undefined> => {
        const localItems = (await this.getShoppingList()) || { lineItems: [] };
        const itemsToShare = (skus ? skus : localItems.lineItems).map((el) => ({
            sku: sanitizeSku(el.sku),
            productType: el.productType
        }));

        const sharePostPayload = {
            products: itemsToShare.filter((i) => i.productType === 'product').map((i) => i.sku),
            materials: itemsToShare.filter((i) => i.productType === 'material').map((i) => i.sku)
        } as any;

        const { shareURL } = (await post(this.shareWishlistUrl!, sharePostPayload)) as LegacyWishlistShareResponse;
        return {
            url: shareURL
        };
    };
}

export type WishlistContent = BffShoppingList | LegacyWishlist | undefined;

export type WishlistBffContext = {
    /**
     * Checks if the item exists in wishlist
     * @param sku SKU of the product
     */
    isInWishlist: (sku: string) => boolean;
    /**
     * Adds the product to the shopping list if not present yet
     * @param sku SKU of the product
     */
    ensureItemExistsInWishlist: (sku: string, productType?: LegacyWishlistProductType) => Promise<void>;
    /**
     * Return the most recent wishlist count
     */
    getWishlistCount: () => number;
    /**
     * Toggles Adds/Removes a product from the wishlist
     */
    toggleItem: (sku: string, showModal?: boolean, productType?: LegacyWishlistProductType) => Promise<void>;
    /**
     *  Removes  an item
     */
    removeItem: (sku: string) => Promise<void>;
    /**
     * Creates the share link for a wishlist
     */
    createShareWishlist: (
        skus?: {
            sku: any;
            productType: LegacyWishlistProductType;
        }[]
    ) => Promise<string | undefined | null>;

    /**
     * Loads the users wishlist and returns it.
     */
    loadWishlistData: (fetchRemote?: boolean) => Promise<WishlistContent>;

    /**
     * Returns a shared wishlist. Doesnt modify the users wishlist
     */
    getSharedWishlistData: (shareId: string) => Promise<WishlistContent>;

    /**
     *  Used by other compontents to identify which wishlist provider is active
     */
    useBffWishlist: () => boolean;

    /**
     * Wishlist Data
     */
    wishlist?: WishlistContent;

    /**
     * Wishlist Count
     */
    wishlistCount?: number;
};

export type WishlistBffContextProps = {
    /**
     * Override the config value for testing
     */
    useProvider?: 'bff' | 'legacy';
};

export const WishlistBffContext = createContext<WishlistBffContext>({} as WishlistBffContext);
export const useWishlistBffContext = (): WishlistBffContext => useContext(WishlistBffContext);

export const WishlistBffProvider: React.FunctionComponent<WishlistBffContextProps> = ({ children, useProvider }) => {
    const wishlistUrl = useConfig('wishlistUrl', '/wishlist');
    const [wishlist, setWishlist] = useState<WishlistContent>({
        lineItems: []
    });

    const useBffWishlist = () => {
        // Storybool override for testing
        if ((useProvider && useProvider === 'bff') || !canUseDOM) {
            return true;
        }
        // Feature flag test
        return useFeature(FeatureFlags.ct_wishlist);
    };

    const provider = useBffWishlist() ? WishlistBffStorage.instance : new WishlistLegacyStorage();

    const getShoppingList = async () => {
        const w = await provider.getShoppingList();
        setWishlist(w);
    };

    // Load the wishlist on monut
    useEffect(() => {
        getShoppingList();
    }, []);

    /**
     * Check if an items exists in wishlist
     * @param sku
     * @returns boolean
     */
    const isInWishlist = (sku: string): boolean => {
        const skuSanitized = provider.sanitizeSku(sku);
        return !!(wishlist && wishlist.lineItems.find((l) => l.sku === skuSanitized));
    };

    /**
     * Local state of the wishlistcount which is set via events
     * even by this provider itself. So multilple individually wrapped
     * components get informed of the changes...
     * e.g.. the wishlist count in the navigation
     */
    const [wishlistCount, setWishlistCount] = useState<number>(0);
    const emit = useEvents('wishlist', { setWishlistCount });
    useEffect(() => {
        emit('setWishlistCount', wishlist?.lineItems.length || 0);
    }, [wishlist]);

    /**
     *  Gets the wishlist count
     * @returns number
     */
    const getWishlistCount = () => wishlist?.lineItems?.length || 0;

    /**
     * Adds an item to the wishlist if its not presen yet
     * @param sku
     * @param productType
     */
    const ensureItemExistsInWishlist = async (sku: string, productType?: LegacyWishlistProductType): Promise<void> => {
        if (!isInWishlist(sku)) {
            await toggleWishlistItemInStorage(sku, productType);
        }
    };

    /**
     * Adds/Removes an item from the wishlist
     * @param sku
     * @param productType
     * @returns
     */
    const toggleWishlistItemInStorage = async (
        sku: string,
        productType?: LegacyWishlistProductType
    ): Promise<WishlistContent> => {
        const skuSanitized = provider.sanitizeSku(sku);
        const itemIsInWishlist = isInWishlist(sku);

        if (itemIsInWishlist) {
            const id = wishlist?.lineItems.find((l) => l.sku === skuSanitized)?.id;
            if (id) {
                const w = await provider.deleteShoppingListItem(id);
                setWishlist(w);
                return w;
            }
        } else {
            const w = useBffWishlist()
                ? await (provider as WishlistBffStorage).createShoppingList([{ sku: skuSanitized }])
                : await (provider as WishlistLegacyStorage).createShoppingList([
                      { sku, productType: productType ? productType : 'product' }
                  ]);

            setWishlist(w);
            return w;
        }
    };

    /**
     * Load  the users wishlist from the provider
     * @param shareId
     * @returns void
     */
    const loadWishlistData = async (fetchRemote?: boolean): Promise<WishlistContent> => {
        if (fetchRemote) {
            const w = await provider.getShoppingList(fetchRemote);
            setWishlist(w);
            return w;
        }
    };

    /**
     * Load  the users wishlist from the provider
     * @param shareId
     * @returns void
     */
    const getSharedWishlistData = async (shareId: string): Promise<WishlistContent> => {
        return await provider.getSharedShoppingList(shareId);
    };

    /**
     * Remove an item from the wishlist via its uuid NOT THE SKU
     * @param uuid
     */
    const removeItem = async (uuid: string) => {
        const w = await provider.deleteShoppingListItem(uuid);
        setWishlist(w);
    };

    // Modal
    const t = {
        title_products: useBffWishlist() ? (
            // we must use the <Translate> component because the translations otherwise are not in context
            <Translate
                id={'wishlist.modal.messages.product_success'}
                defaultMessage={'The product has been successfully added to your wish list.'}
            />
        ) : (
            useTranslations(
                'wishlist_add_product_success',
                'The product has been successfully added to your wish list.'
            )
        ),
        title_materials: useBffWishlist() ? (
            // we must use the <Translate> component because the translations otherwise are not in context
            <Translate
                id="wishlist.modal.messages.material_success"
                defaultMessage="The material has been successfully added to your wish list."
            />
        ) : (
            useTranslations(
                'wishlist_add_material_success',
                'The material has been successfully added to your wish list.'
            )
        ),
        continue: useBffWishlist() ? (
            // we must use the <Translate> component because the translations otherwise are not in context
            <Translate id={'wishlist.modal.actions.continue_shopping'} defaultMessage={'Continue shopping'} />
        ) : (
            useTranslations('wishlist_continue', 'Continue shopping')
        ),
        go_to_wishlist: useBffWishlist() ? (
            <Translate id={'wishlist.modal.actions.go_to_wishlist'} defaultMessage={'Go to wish list'} />
        ) : (
            useTranslations('wishlist_to_wishlist', 'Go to wish list')
        )
    };

    const { modal, openModal } = useConfirmModal({
        title: t.title_products,
        cancel: t.continue,
        confirm: t.go_to_wishlist
    });

    const toggleItem = async (sku: string, showModal?: boolean, productType?: LegacyWishlistProductType) => {
        const wishlistResponse = await toggleWishlistItemInStorage(sku, productType);
        const item = wishlistResponse?.lineItems.find((l: any) => l.sku === sanitizeSku(sku));
        if (item && showModal) {
            const gotoWishlist = await openModal({
                title: item.productType === 'material' ? t.title_materials : t.title_products
            });
            if (gotoWishlist) {
                window.location.href = wishlistUrl;
            }
        }
    };

    const createShareWishlist = async (
        skus?: {
            sku: any;
            productType: LegacyWishlistProductType;
        }[]
    ) => {
        const data = await provider.shareShoppingList(skus);
        return data?.url;
    };

    return (
        <WishlistBffContext.Provider
            value={{
                wishlistCount,
                isInWishlist,
                ensureItemExistsInWishlist,
                getWishlistCount,
                toggleItem,
                removeItem,
                createShareWishlist,
                wishlist,
                loadWishlistData,
                getSharedWishlistData,
                useBffWishlist
            }}
        >
            {children}
            {modal}
        </WishlistBffContext.Provider>
    );
};

type WishlistButton = {
    /**
     * The sku of the product
     */
    sku: string;
    /**
     * The URL to the wishlist
     */
    wishlistUrl?: string;
    /**
     * Extra className
     */
    className?: string;
    /**
     * Callback
     */
    showModal?: boolean;
    /**
     * Type
     */
    type: LegacyWishlistProductType;
};

/**
 * Wishlist Button
 */
import { addGaEvent } from 'common/primitives/analytics';
import { BlankButton } from 'common/primitives/buttons';
import V2Heart from 'common/primitives/icons/components/V2Heart';
import { colors } from 'common/styles';
import { sanitizeSku } from 'common/utils/sanitize';
import { css, cx } from 'linaria';
import { useEvents } from './useEvents';

const styles = {
    wrapper: css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        /* &:hover {
            svg path {
                fill: ${colors.primary};
            }
        } */
    `,
    heart: css`
        width: 21px;
        height: 21px;
    `,
    heartFilled: css`
        path {
            fill: ${colors.primary};
        }
    `
};

export const WishlistButton: React.FunctionComponent<WishlistButton> = ({
    sku,
    className,
    showModal = false,
    type
}) => {
    const wishlistContext: WishlistBffContext = useWishlistBffContext();
    const { wishlist } = wishlistContext;

    const isInWishlist = wishlist && wishlistContext.isInWishlist ? wishlistContext.isInWishlist(sku) : false;

    const toggle = (e: any) => {
        e.preventDefault();
        // Analytics
        addGaEvent({
            eventCategory: 'Product Interactions',
            eventAction: 'Wishlist' + (isInWishlist ? ' | removed' : ' | added'),
            eventLabel: sku
        });
        wishlistContext.toggleItem(sku, showModal, type);
    };
    return (
        <BlankButton testId={`AddToWishlist-${sku}`} onClick={toggle} className={cx(styles.wrapper, className)}>
            <V2Heart className={cx(styles.heart, isInWishlist && styles.heartFilled)} />
        </BlankButton>
    );
};
