import { colors, linkColor, media, rh } from 'common/styles';
import { css, cx } from 'linaria';
import React, { useEffect, useState } from 'react';

import get from 'lodash/get';

const styles = {
    wrapper: css`
        width: 100%;
    `,
    navigation: css`
        display: inline-flex;
        max-width: 100%;
        overflow-x: auto;
        user-select: none;
        ${media.desktopAndUp} {
            margin-left: ${rh(1)};
        }
        a {
            padding: ${rh(0.25)} ${rh(0.5)};
            padding-left: 0;
            padding-right: ${rh(1)};
            flex: 1;
            text-align: center;
            &:last-child {
                margin-right: 0px;
            }
        }
    `,
    tab: css`
        text-decoration: none;
        white-space: nowrap;
        ${linkColor(colors.black, colors.primary)};
    `,
    tabActive: css`
        ${linkColor(colors.primary, colors.primary)};
    `,
    panel: css`
        background: #fff;
        padding: ${rh(0.5)};
        ${media.desktopAndUp} {
            padding: ${rh(1)};
            padding-bottom: ${rh(2)};
        }
    `
};

interface VitraTabsProps {
    className?: string;
    activeTab?: string | null;
    onSelect?: (id: string) => void;
    activeClassName?: string;
}

interface VitraTabsPanelProps {
    id: string;
    title: string | JSX.Element;
    className?: string;
    href?: string;
}

export const VitraTabs: React.FunctionComponent<VitraTabsProps> = (props) => {
    const tabs = Array.isArray(props.children) ? props.children : [];
    const navigation = tabs.map((child: any) => child.props);
    const firstTab = props.activeTab ? props.activeTab : get(navigation, '[0].id');
    const [activeTabId, setActiveTabId] = useState(firstTab);

    const setActiveTab = (id: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setActiveTabId(id);
        if (props.onSelect) {
            props.onSelect(id);
        }
    };

    useEffect(() => {
        if (props.activeTab) {
            setActiveTabId(props.activeTab);
        }
    }, [props.activeTab]);

    return (
        <div className={cx(styles.wrapper, props.className)}>
            <div className={cx('tabs__navigation', styles.navigation)}>
                {navigation.map((item) => {
                    const isActive = item.id === activeTabId;
                    const tabClasses = cx(
                        styles.tab,
                        props.className,
                        isActive && styles.tabActive,
                        isActive && props.activeClassName
                    );

                    return (
                        (!item.href && (
                            <a className={tabClasses} onClick={setActiveTab(item.id)} href={`#${item.id}`}>
                                {item.title || item.name}
                            </a>
                        )) || (
                            <a className={tabClasses} href={item.href} target="_blank" rel="noreferrer">
                                {item.title || item.name}
                            </a>
                        )
                    );
                })}
            </div>
            {tabs.filter((tab: any) => tab.props.id === activeTabId)}
        </div>
    );
};

export const VitraTabsPanel: React.FunctionComponent<VitraTabsPanelProps> = (props) => {
    const tabPanelClass = cx(styles.panel, props.className);
    return <div className={tabPanelClass}>{props.children}</div>;
};
