import V2ArrowLeftMedium from 'common/primitives/icons/components/V2ArrowLeftMedium';
import V2ArrowRightMedium from 'common/primitives/icons/components/V2ArrowRightMedium';
import { colors, rh } from 'common/styles';
import configureDayjs from 'common/utils/time';
import dayjs, { Dayjs } from 'dayjs';
import { css, cx } from 'linaria';
import times from 'lodash/times';
import React, { useState } from 'react';

const styles = {
    wrapper: css`
        width: 100%;
        background: #fff;
        color: ${colors.black};
        > * {
            user-select: none;
        }
    `,
    calendarCaption: css`
        display: flex;
        justify-content: space-between;
        width: 100%;
        border-bottom: 1px solid ${colors.inputBorderColor};
    `,
    calendarCaptionTitle: css`
        padding: ${rh(0.5)} 0;
    `,
    calendarCaptionPrevNext: css`
        width: 100px;
        padding: ${rh(0.5)} 0;
        &:hover {
            cursor: pointer;
            svg {
                fill: ${colors.primary};
            }
        }
    `,
    calendar: css`
        width: 100%;
    `,
    calendarWeekday: css`
        font-weight: 600;
        padding-top: ${rh(0.5)};
    `,
    calendarDayOfMonth: css`
        text-align: center;
        &:hover {
            cursor: pointer;
            color: ${colors.primary};
        }
    `,
    calendarDayOfMonthToday: css`
        color: ${colors.primary};
        font-weight: 600;
    `,
    calendarDayOfMonthSelected: css`
        background: ${colors.black};
        color: white;
    `,
    calendarDayOfMonthPassed: css`
        color: ${colors.textGrey} !important;
        &:hover {
            cursor: default !important;
        }
    `,
    calendarDayOfMonthDisabled: css`
        color: ${colors.textGrey} !important;
        opacity: 0.5;
        position: relative;
        pointer-events: none;
        text-decoration: line-through;
        &:hover {
            cursor: not-allowed !important;
        }
    `
};

interface CalendarProps {
    className?: string;

    onChange?: (date: string) => void;

    /**
     * Validates if the date can be selected
     */
    validateDate?: (date: dayjs.Dayjs) => boolean;

    /**
     * Locale to translate weeks and weekdays
     */
    locale: string;

    /**
     * Current date value - if not set its today
     */
    date?: string;

    /**
     * A list of start and enddates which shall be not selectable
     * and an optional corresponding error message
     */
    blockedDateRanges?: [{ startDate: string; endDate?: string }];

    /**
     * List of blocked weekdays. The week starts with sunday and
     * sunday has an index of 0
     */
    blockedWeekdays?: number[];
}

const Calendar: React.FunctionComponent<CalendarProps> = (props) => {
    const { locale = 'en' } = props;
    configureDayjs(locale);

    /**
     * Calendar functions
     */
    const [monthDate, setMonthDate] = useState(
        (props.date && dayjs(props.date).hour(12).minute(0).second(0).millisecond(0)) ||
            // We set to 12 o`clock to be sure that we dont get a wrong day due to timezones
            dayjs().hour(12).minute(0).second(0).millisecond(0).toISOString()
    );
    const nextMonth = () => {
        setMonthDate(dayjs(monthDate).add(1, 'month').toISOString());
    };
    const prevMonth = () => {
        setMonthDate(dayjs(monthDate).subtract(1, 'month').toISOString());
    };
    const onChange = (date: Dayjs) => {
        if (props.onChange) {
            props.onChange(dayjs.utc(date).toISOString());
        }
    };

    const isBlockedDate = (date: Dayjs): boolean => {
        const { blockedDateRanges, blockedWeekdays } = props;

        let isBlocked = false;

        if (!blockedDateRanges && !blockedWeekdays) {
            return false;
        }

        if (blockedWeekdays) {
            for (const index in blockedWeekdays) {
                const blockedDayOfWeek = blockedWeekdays[index as any];
                if (dayjs(date).day() === blockedDayOfWeek) {
                    isBlocked = true;
                }
            }
        }

        if (blockedDateRanges) {
            for (const index in blockedDateRanges) {
                const range = blockedDateRanges[index as any];

                // If we have an enddate we check in between
                if (range.endDate && dayjs(date).isBetween(range.startDate, range.endDate, 'day', '[]')) {
                    isBlocked = true;
                }

                // if we dont have an endDate everythng after is blocked
                if (
                    !range.endDate &&
                    (dayjs(date).isAfter(range.startDate, 'day') || dayjs(date).isSame(dayjs(range.startDate)))
                ) {
                    isBlocked = true;
                }
            }
        }

        return isBlocked;
    };

    const renderCalendar = () => {
        let month = dayjs.utc(monthDate);
        month = month.date(1);

        const daysInMonth = parseInt(dayjs.utc(month).endOf('month').format('D'), 10);
        const offset = month.weekday(); // the positional value of the first day in the month, e.g. Friday is 5
        const numRows = Math.ceil((offset + daysInMonth) / 7);
        let iDate = dayjs.utc(month).subtract(offset, 'day');

        const html: any = [];

        times(numRows, () => {
            const weekRow: any = [];
            const weekCells: any = [];
            times(7, () => {
                const d = dayjs.utc(iDate);
                const isSelected = dayjs(d).isSame(dayjs(props.date), 'day');
                const isToday = dayjs(d).isSame(dayjs(), 'day');
                const isOtherMonth = dayjs(d).month() !== dayjs(monthDate).month();
                let isActive = dayjs().isBefore(dayjs(d));
                if (isActive && props.validateDate) {
                    isActive = props.validateDate(d);
                }

                const classes = cx(
                    styles.calendarDayOfMonth,
                    isSelected && styles.calendarDayOfMonthSelected,
                    isToday && styles.calendarDayOfMonthToday,
                    isOtherMonth && 'calendar__other_month',
                    isBlockedDate(d) && styles.calendarDayOfMonthDisabled,
                    !isActive && styles.calendarDayOfMonthPassed
                );

                weekCells.push(
                    <td key={d.unix()} className={classes} onClick={() => isActive && onChange(d)}>
                        <i></i>
                        <span>{d.format('D')}</span>
                    </td>
                );
                iDate = iDate.add(1, 'day');
            });
            weekRow.push(<tr>{weekCells}</tr>);
            html.push(weekRow);
        });
        return html;
    };

    /**
     * The wee labels above the calendar
     */
    const renderWeekDays = () => {
        const weekdaysHtml: any = [];
        let d = dayjs().startOf('week').weekday(0);
        times(7, (i) => {
            weekdaysHtml.push(
                <th className={styles.calendarWeekday} key={'wday-' + i}>
                    {d.format('dd')}
                </th>
            );
            d = d.add(1, 'day');
        });
        return weekdaysHtml;
    };

    return (
        <div className={cx(styles.wrapper, props.className)}>
            <caption className={styles.calendarCaption}>
                <span className={styles.calendarCaptionPrevNext} onClick={prevMonth}>
                    <V2ArrowLeftMedium />
                </span>
                <span className={styles.calendarCaptionTitle}>{dayjs(monthDate).format('MMMM, YYYY')}</span>
                <span className={styles.calendarCaptionPrevNext} onClick={nextMonth}>
                    <V2ArrowRightMedium />
                </span>
            </caption>
            <table className={styles.calendar}>
                <thead>
                    <tr>{renderWeekDays()}</tr>
                </thead>
                <tbody>{renderCalendar()}</tbody>
            </table>
        </div>
    );
};

export default Calendar;
