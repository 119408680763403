/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

export enum colors {
    black = '#333',
    blackHover = '#666',
    blackDisabled = '#dfdfdf',

    white = '#fff',
    whiteHover = '#c6c6c6',
    whiteDisabled = '#525252',
    blankDisabled = '#E3E3E3',

    navigationBackground = '#f8f8f8',
    darkGreyText = '#555',

    primary = '#D43E42',
    primaryDark = '#C12A32',
    green = '#43bf2c',
    lightgrey = '#CCC',
    silver = '#f5f5f5',
    silverDark = '#f2f2f2',
    textGrey = '#767676',
    blue = '#a7daf6',
    error = '#cc2b2b',
    pink = '#ffeae6',
    salmon = '#f78181',
    footerGrey = '#E6E6E6',
    footerGreyLight = '#F7F7F7',
    infoBanner = '#f6f3eb',
    stockGray = '#eeeeee',
    inputBorderColor = '#dddddd',
    inputBorderColorDarker = '#bfbfbf', // Especially used for radio buttons
    inputDisabledBackground = '#F7F7F7',
    borderColorConfigure = 'rgba(221, 221, 221, .5)',
    navigationTop = '#e6e5e6',

    findVitraColorSilver = '#F5F5F5',
    findVitraColorGray = '#767676',
    findVitraColorBlack = '#333333',
    findVitraColorRed = '#FF6564',
    findVitraColorOrange = '#FF9F00',
    findVitraColorGreen = 'rgb(0, 132, 137)',
    findVitraColorPremiumPartnerWithSpace = '#008489',
    findVitraColorPremiumPartner = '#64b5db',
    findVitraColorBorder = '#D8D8D8',

    formError = '#cc2b2b',
    checkoutBorder = silverDark,
    checkoutSection = '#F9F9FA'
}
